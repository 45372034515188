import { openDB } from "idb";

const dbName = "c2pDataBase";
const storeName = "c2pDataStore";

const openDatabase = async () => {
  const db = await openDB(dbName, 1, {
    upgrade(db) {
      db.createObjectStore(storeName, {
        keyPath: "id",
      });
    },
  });
  return db;
};

export const addDataToDB = async (data) => {
  const db = await openDatabase();
  const tx = db.transaction(storeName, "readwrite");
  const store = tx.objectStore(storeName);
  await store.add(data);
};

export const updateDataInDB = async (newData) => {
  const db = await openDatabase();
  const tx = db.transaction(storeName, "readwrite");
  const store = tx.objectStore(storeName);
  await store.put(newData);
  await tx.done;
};

export const clearStore = async () => {
  const db = await openDatabase();
  const request = db
    .transaction(storeName, "readwrite")
    .objectStore(storeName)
    .clear();
  return request;
};

export const getData = async (key) => {
  const db = await openDatabase();
  const tx = db.transaction(storeName, "readonly");
  const store = tx.objectStore(storeName);
  const data = await store.get(key);
  return data;
};
