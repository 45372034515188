import { createSlice } from "@reduxjs/toolkit";
import { clearStore } from "../util/indexedDB";
const initialState = {
  chatMessagesImages: [],
};

const fileSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    addFileData: (state, action) => {
      const { id } = action.payload;
      const tmpValue = [...state.chatMessagesImages];
      const dataIndex = tmpValue.findIndex((item) => item.id === id);

      if (dataIndex === -1) {
        state.chatMessagesImages = [
          ...state.chatMessagesImages,
          action.payload,
        ];
        // addDataToDB(action.payload);
      }
    },
    clearFileData: (state) => {
      state.chatMessagesImages = [];
      clearStore();
    },
    // updateFileData: (state, action) => {
    //   const { id, newData } = action.payload;

    //   const tmpValue = [...state.dummyData];
    //   const dataIndex = tmpValue.findIndex((item) => item.id === id);
    //   if (dataIndex !== -1) {
    //     tmpValue[dataIndex] = newData;
    //     state.dummyData = tmpValue;
    //     updateDataInDB(id, newData);
    //   }
    // },
  },
});

export const { addFileData, updateFileData, clearFileData } = fileSlice.actions;
export default fileSlice.reducer;
