/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-prototype-builtins */
/* eslint-disable valid-typeof */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, memo, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmojiPicker from "emoji-picker-react";

import {
  getUpdateUnreadChatMessage,
  timestampToDateTime,
} from "../Helpers/common";
import UserInputMessageArea from "./userInputMessageArea";
import ChatDelete from "../models/ChatDelete";
import UserProfilePopOver from "../models/userProfilePopOver";
import {
  OnClickOutside,
  UrlPreview,
  onEmojiClickCommonHandler,
  previewLinkIcon,
  userProfileHover,
} from "../Helpers/chatCommon";
import AttachmentsFiles from "./attachmentFile";
import { setSelectedReactionUser } from "../redux/chatSlice";
import ReactionUserPopOver from "../models/reactionUsersPopOver";

const ChatBoardOutgoing = ({
  value,
  userInfo,
  lastMessage,
  editMessageHandler,
  messageEditId,
  moreActionHandler,
  moreActionEnableId,
  showPicker,
  emojiRef,
  showHideEmojiPickerHandler,
  showMessagePicker,
  showHideEmojiMsgPickerHandler,
  emojiMsgRef,
  senderID,
  replyHandler,
  setShowPicker,
  setShowMessagePicker,
  setMoreActionEnableId,
  setStopScrollBottom,
}) => {
  const dispatch = useDispatch();
  let IsNewLineExist = null;

  if (
    value.message !== "c2-attach-files" &&
    value.message !== "c2-attach-recording-file"
  ) {
    IsNewLineExist = /\r|\n/.exec(value.message);
  }

  const actionRef = useRef(null);
  const allAttendeeList = useSelector((state) => state.call.allAttendeeList);

  const userProfileId = useSelector((state) => state.chat.userProfileId);

  const deletedMessageIds = useSelector(
    (state) => state.chat.deletedMessageIds,
  );
  const msgMentionId = useSelector((state) => state.chat.msgMentionId);
  const msgMentionUser = useSelector((state) => state.chat.msgMentionUser);

  const deleteChatData = useSelector((state) => state.chat.deleteChatData);
  const selectedLanguage = useSelector((state) => state.chat.selectedLanguage);
  const authSessionUser = useSelector(
    (state) => state.authUserDetail.authSessionUser,
  );

  const popupClass = useSelector((state) => state.chat.popupClass);
  const selectedReactionUser = useSelector(
    (state) => state.chat.selectedReactionUser,
  );

  const [showDelete, setShowDelete] = useState(false);
  const [deleteData, setDeleteData] = useState({});

  useEffect(() => {
    if (deleteChatData?.messageID) {
      let deletedID = document.getElementById(deleteChatData.messageID);
      if (deletedID) {
        deletedID.classList.add("chat_row_hide");
      }
    }
  }, [deleteChatData]);

  const showMessage = (value) => {
    if (messageEditId === value.id) {
      return (
        <UserInputMessageArea
          componentType="editMessage"
          editableData={value}
          editMessageHandler={editMessageHandler}
        />
      );
    } else {
      if (
        value.attachments !== undefined &&
        value.attachments.length > 0 &&
        (value.message === "c2-attach-files" ||
          value.message === "c2-attach-recording-file")
      ) {
        if (
          (value.message === "c2-attach-recording-file" &&
            value.senderID === authSessionUser?.user_id) ||
          value.message === "c2-attach-files"
        ) {
          return (
            <div className="d-flex file_send_info gap-10 flex-wrap">
              {value.attachments.map((attachmentItem, attachmentIndex) => (
                <div key={attachmentIndex} className="w-100">
                  {/* eslint-disable-next-line indent */}
                  {/* eslint-disable-next-line indent */}
                  <AttachmentsFiles
                    attachmentItem={attachmentItem}
                    messageDetail={value}
                  />
                </div>
              ))}
            </div>
          );
        } else {
          return false;
        }
      } else {
        if (IsNewLineExist !== null) {
          const newMessage =
            value.preview &&
            Array.isArray(value.preview) &&
            value.preview.length > 0
              ? value.message_array.join(" ")
              : value.message;
          return (
            <>
              {newMessage?.split("\n").map((str, index) => (
                <>
                  <div
                    key={index}
                    className="d-flex file_send_info gap-10 flex-wrap share-link"
                  >
                    <p dangerouslySetInnerHTML={{ __html: str }} />
                    {previewLinkIcon(value)}

                    {msgMentionId === value.msgUniqueID &&
                      value.type === "group" && (
                        <UserProfilePopOver
                          userInfo={
                            msgMentionId === value.msgUniqueID
                              ? allAttendeeList?.find(
                                  (userItem) =>
                                    userItem?.user_id === msgMentionUser,
                                ) ?? {}
                              : userInfo
                          }
                          popupClass={popupClass}
                          selectedLanguage={selectedLanguage}
                        />
                      )}
                    {messageEditId !== value.id && value.edited === true && (
                      <p className="edit_message_display">(edited)</p>
                    )}
                  </div>
                </>
              ))}
              {value?.preview ? UrlPreview(value?.preview) : null}
            </>
          );
        } else {
          return (
            <>
              <div className="d-flex file_send_info gap-10 flex-wrap">
                <p
                  className="break-words"
                  dangerouslySetInnerHTML={{
                    __html:
                      value.preview &&
                      Array.isArray(value.preview) &&
                      value.preview.length > 0
                        ? value.message_array.join(" ")
                        : value.message,
                  }}
                />
                {previewLinkIcon(value)}
                {/* <p className="break-words"> {value.message}</p> */}

                {msgMentionId === value.msgUniqueID &&
                  value.type === "group" && (
                    <UserProfilePopOver
                      userInfo={
                        msgMentionId === value.msgUniqueID
                          ? allAttendeeList?.find(
                              (userItem) =>
                                userItem?.user_id === msgMentionUser,
                            ) ?? {}
                          : userInfo
                      }
                      popupClass={popupClass}
                      selectedLanguage={selectedLanguage}
                    />
                  )}
                {messageEditId !== value.id && value.edited === true && (
                  <p className="edit_message_display">(edited)</p>
                )}
              </div>

              {value?.preview ? UrlPreview(value?.preview) : null}
            </>
          );
        }
      }
    }
  };

  const onEmojiClick = (emojiObject) => {
    onEmojiClickCommonHandler(
      emojiObject,
      value,
      setShowPicker,
      setShowMessagePicker,
    );
  };

  const addRemoveEmoji = (emojiItem, value) => {
    onEmojiClickCommonHandler(
      emojiItem,
      value,
      setShowPicker,
      setShowMessagePicker,
    );
  };

  OnClickOutside(actionRef, () => setMoreActionEnableId(""));

  return (
    <>
      <div
        className={`chat_row ${lastMessage === true ? "mb-0" : ""} ${
          deletedMessageIds?.includes(value?.id) ? "chat_row_hide" : ""
        }`}
        id={value.id}
        key={value.id}
        onMouseLeave={() => userProfileHover({})}
      >
        <div className="w-100 d-flex  max-w-100">
          <div
            className="avatar"
            onMouseEnter={() => userProfileHover(value.id)}
          >
            <img
              className="h-100"
              src={`${
                authSessionUser.user_profile_image ? (
                  authSessionUser.user_profile_image
                ) : (
                  <svg viewBox="3 2 18 19">
                    <path
                      fill="#425b76"
                      d="M7.5 6.5C7.5 8.981 9.519 11 12 11s4.5-2.019 4.5-4.5S14.481 2 12 2 7.5 4.019 7.5 6.5zM20 21h1v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h17z"
                    ></path>
                  </svg>
                )
              }`}
            ></img>
          </div>
          {userProfileId === value.id && (
            <UserProfilePopOver
              userInfo={
                msgMentionId === value.msgUniqueID
                  ? allAttendeeList?.find(
                      (userItem) => userItem?.user_id === msgMentionUser,
                    ) ?? {}
                  : userInfo
              }
              popupClass={popupClass}
              selectedLanguage={selectedLanguage}
            />
          )}
          <div
            className={`bubble inner-bubble ${
              value.isReply === true && "reply"
            }`}
          >
            {value.isReply === true && (
              <h3 className="Name-messenger">{userInfo.name} </h3>
            )}
            <div className={`${value.isReply === true && "reply-inner"}`}>
              {value.isReply === true ? (
                <h3 className="Name-messenger flex-column align-items-start">
                  <div>
                    <p
                      className="break-words text-grey"
                      dangerouslySetInnerHTML={{ __html: value.earlyMessage }}
                    />
                    {msgMentionId === value.msgUniqueID &&
                      value.type === "group" && (
                        <UserProfilePopOver
                          userInfo={
                            msgMentionId === value.msgUniqueID
                              ? allAttendeeList?.find(
                                  (userItem) =>
                                    userItem?.user_id === msgMentionUser,
                                ) ?? {}
                              : userInfo
                          }
                          popupClass={popupClass}
                          selectedLanguage={selectedLanguage}
                        />
                      )}
                    <div className="time ml-0 mt-1">
                      {allAttendeeList?.find(
                        (findItem) =>
                          findItem.user_id === value.earlyMsgSenderID,
                      )?.name ?? ""}
                      , {timestampToDateTime(value.earlyMsgTimestamp)}
                    </div>
                  </div>
                </h3>
              ) : (
                <h3 className="Name-messenger">
                  {userInfo.name}{" "}
                  <div className="time">
                    {timestampToDateTime(value.timestamp)}
                  </div>
                </h3>
              )}
            </div>
            {showMessage(value)}
          </div>
        </div>

        <div
          className={`chat_actions ${
            moreActionEnableId === value.id || showPicker === value.id
              ? "display_flex"
              : "display_none"
          }`}
        >
          <>
            <div className="chat_actions_span">
              <button
                type="submit"
                className="btn-icon justify-content-center"
                onClick={() => showHideEmojiPickerHandler(value.id)}
              >
                <svg height="19" viewBox="2 2 20 20">
                  <path
                    fill="#b8c0c9"
                    d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"
                  ></path>
                  <path
                    fill="#b8c0c9"
                    d="M14.829 14.828a4.055 4.055 0 0 1-1.272.858 4.002 4.002 0 0 1-4.875-1.45l-1.658 1.119a6.063 6.063 0 0 0 1.621 1.62 5.963 5.963 0 0 0 2.148.903 6.042 6.042 0 0 0 2.415 0 5.972 5.972 0 0 0 2.148-.903c.313-.212.612-.458.886-.731.272-.271.52-.571.734-.889l-1.658-1.119a4.017 4.017 0 0 1-.489.592z"
                  ></path>
                  <circle fill="#b8c0c9" cx="8.5" cy="10.5" r="1.5"></circle>
                  <circle
                    fill="#b8c0c9"
                    cx="15.493"
                    cy="10.493"
                    r="1.493"
                  ></circle>
                </svg>
              </button>

              {showPicker === value.id && (
                <div ref={emojiRef} className="emoji-main right-0 left-auto">
                  <EmojiPicker
                    height={400}
                    // width={350}
                    onEmojiClick={onEmojiClick}
                    skinTonePickerLocation="SEARCH"
                    emojiVersion="5.0"
                  />
                </div>
              )}
              {value.message !== "c2-attach-recording-file" && (
                <div className="dropdown">
                  <a
                    className="justify-content-center dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={() => {
                      moreActionHandler(value.id);
                    }}
                  >
                    <svg width="4px" viewBox="10 4 4 16">
                      <path
                        fill="#b8c0c9"
                        d="M12 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 12c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
                      ></path>
                    </svg>
                  </a>

                  <ul
                    className="chat_actions_dropdown flex-column dropdown-menu"
                    aria-labelledby={`${"dropdownMenuButton"}`}
                    ref={actionRef}
                  >
                    {value.message !== "c2-attach-files" && (
                      <>
                        <li
                          className="chat_actions_li"
                          onClick={() => {
                            replyHandler(value);
                            moreActionHandler("");
                          }}
                        >
                          <a className="chat_actions_tag">Reply</a>
                        </li>
                        <li
                          className="chat_actions_li"
                          onClick={() => {
                            editMessageHandler(value.id);
                            setStopScrollBottom(true);
                          }}
                        >
                          <a className="chat_actions_tag">Edit</a>
                        </li>
                      </>
                    )}
                    <li
                      className="chat_actions_li"
                      onClick={() => {
                        setShowDelete(true);
                        setDeleteData(value);
                        moreActionHandler("");
                      }}
                    >
                      <a className="chat_actions_tag">Delete</a>
                    </li>
                    <li
                      className="chat_actions_li"
                      onClick={() =>
                        getUpdateUnreadChatMessage(
                          value?.type === "group"
                            ? value?.receiverID
                            : authSessionUser?.user_id === value.senderID
                            ? value?.receiverID
                            : value.senderID,
                          "countUpdate",
                        )
                      }
                    >
                      <a className="chat_actions_tag">Mark unread</a>
                    </li>
                  </ul>
                </div>
              )}
              {showDelete && (
                <ChatDelete
                  showDelete={showDelete}
                  handleClose={() => setShowDelete(false)}
                  deleteData={deleteData}
                  userInfo={userInfo}
                  selectedLanguage={selectedLanguage}
                />
              )}
            </div>
          </>
        </div>
        {value?.reactions?.length > 0 && (
          <div className="reactions">
            {value?.reactions?.map((item, index) => (
              <div
                key={index}
                className="position-relative d-flex justify-content-center"
              >
                <a
                  className={`reactions-tag ${
                    item?.user_id?.includes(senderID) ? "active" : ""
                  }`}
                  key={index}
                  onMouseEnter={() =>
                    dispatch(
                      setSelectedReactionUser({
                        msgID: value?.id,
                        reactionIndex: index,
                        reactionUserId: item?.user_id ?? "",
                        emoji: item.emoji,
                        names: item?.names ?? [],
                      }),
                    )
                  }
                  onMouseLeave={() => dispatch(setSelectedReactionUser({}))}
                  onClick={() => addRemoveEmoji(item, value)}
                >
                  <span className="mr-1">{item.emoji}</span>
                  {item.count}
                </a>

                {selectedReactionUser &&
                Object.keys(selectedReactionUser).length > 0 &&
                selectedReactionUser?.msgID === value.id &&
                selectedReactionUser?.reactionIndex === index ? (
                  <ReactionUserPopOver />
                ) : null}
              </div>
            ))}
            {value?.reactions?.length > 0 && (
              <div className="position-relative reactions-tag">
                <button
                  type="submit"
                  className="btn-icon"
                  onClick={() => showHideEmojiMsgPickerHandler(value.id)}
                >
                  <svg width="15" viewBox="2 2 20 20">
                    <path
                      fill="#b8c0c9"
                      d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"
                    ></path>
                    <path
                      fill="#b8c0c9"
                      d="M14.829 14.828a4.055 4.055 0 0 1-1.272.858 4.002 4.002 0 0 1-4.875-1.45l-1.658 1.119a6.063 6.063 0 0 0 1.621 1.62 5.963 5.963 0 0 0 2.148.903 6.042 6.042 0 0 0 2.415 0 5.972 5.972 0 0 0 2.148-.903c.313-.212.612-.458.886-.731.272-.271.52-.571.734-.889l-1.658-1.119a4.017 4.017 0 0 1-.489.592z"
                    ></path>
                    <circle fill="#b8c0c9" cx="8.5" cy="10.5" r="1.5"></circle>
                    <circle
                      fill="#b8c0c9"
                      cx="15.493"
                      cy="10.493"
                      r="1.493"
                    ></circle>
                  </svg>
                </button>
                {showMessagePicker === value.id && (
                  <div ref={emojiMsgRef} className="emoji-main">
                    <EmojiPicker
                      height={400}
                      // width={350}
                      onEmojiClick={onEmojiClick}
                      skinTonePickerLocation="SEARCH"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default memo(ChatBoardOutgoing);
