import React, { memo } from "react";
import { useSelector } from "react-redux";

import { globalStrings } from "../util/translation/languages";
import { isElectron } from "../Helpers/common";

const ChatBoardWelcome = (props) => {
  const { selectedLanguage } = props;
  const companyDetail = useSelector(
    (state) => state.authUserDetail.companyDetail,
  );
  const sessionThemeData = useSelector(
    (state) => state.authUserDetail.sessionThemeData,
  );

  return (
    <>
      <div className="logo_loader d-flex flex-column">
        <div className="company-logo-container flex-column align-items-start">
          {sessionThemeData ? (
            <img
              src={`${companyDetail?.company_logo}`}
              alt="Logo"
              className="company-logo"
            />
          ) : (
            <img
              src="../assets/images/c2connect-logo-theme-light.svg"
              alt="Logo"
              className="company-logo"
            />
          )}
          <small className="pl-7 font-75">
            {!isElectron
              ? "C2Connect Web v2.10.13"
              : "C2Connect Desktop v2.10.13"}
          </small>
        </div>
        <div className="logo_loader__footer position-absolute text-white d-flex align-items-center index-1 font--medium">
          {globalStrings[selectedLanguage]?.$pms}&nbsp;
          <img
            src="../assets/images/C2_Perform_Logo.svg"
            alt="Logo"
            className="ml-1"
          />
        </div>
      </div>
    </>
  );
};

export default memo(ChatBoardWelcome);
