/* eslint-disable no-unused-vars */
const JitsiMeetJS = window.JitsiMeetJS;
import store from "../../../store";
import { config } from "./config";

import {
  setiframeLoading,
  setvideoContainerVisible,
  setcameraDeviceId,
  setmicDeviceId,
  setisActiveMic,
  setisAvailableMic,
  setisActiveCamera,
  setisAvailableCamera,
  setnumpartcipient,
  setisActiveScreenSharing,
  setwasActiveCamera,
  setisActiveRecording,
  setrecordingLoader,
  setrecorderActiveSessionid,
  setrecordingTime,
  setRecordingInterval,
  setCameraDeviceList,
  setSpeakerDeviceList,
  setHasAudioPermission,
  setHasVideoPermission,
  // setSpeakerDevice,
  setMicrophoneDeviceList,
  trackAdd,
  trackRemove,
  trackMediaTypeUpdate,
  trackMuteUpdate,
  partcipantAdd,
  partcipantRemove,
  addLocalDesktopVideoTrack,
  muteLocalDesktopVideoTrack,
  trackAddLocal,
  trackMuteUpdateLocal,
  partcipantRemoveAll,
  removeLocalDesktopVideoTrack,
  activeSpeakerChange,
  addLocalVideoTrack,
  updateLocalVideoTrackMute,
  addLocalAudioTrack,
  setSharedScreenType,
  setIsActivePenDraw,
  setLocalJid,
  setisActiveSpeaker,
} from "./libSlice";
import {
  checkAvSettingExist,
  startRecordingApi,
  stopRecordingApi,
} from "../common";
import { _userUpdate } from "../../../SocketIo";
export { JitsiMeetJS as default };
import createTFLiteModule from "./tflite/tflite";
import createTFLiteSIMDModule from "./tflite/tflite-simd";
import { v4 as uuidv4 } from "uuid";
import { setRecordingID } from "../../redux/callSlice";
import {
  updateCallParticipantID,
  updateLastSpeakerTime,
  updateScreenshareTypeGlobally,
} from "../CallCommon";

export const browser = JitsiMeetJS.util.browser;
export const JitsiConferenceErrors = JitsiMeetJS.errors.conference;
export const JitsiConferenceEvents = JitsiMeetJS.events.conference;
export const JitsiConnectionErrors = JitsiMeetJS.errors.connection;
export const JitsiConnectionEvents = JitsiMeetJS.events.connection;
export const JitsiConnectionQualityEvents =
  JitsiMeetJS.events.connectionQuality;
export const JitsiDetectionEvents = JitsiMeetJS.events.detection;
export const JitsiE2ePingEvents = JitsiMeetJS.events.e2eping;
export const JitsiMediaDevicesEvents = JitsiMeetJS.events.mediaDevices;
export const JitsiParticipantConnectionStatus =
  JitsiMeetJS.constants.participantConnectionStatus;
export const JitsiTrackStreamingStatus =
  JitsiMeetJS.constants.trackStreamingStatus;
export const JitsiRecordingConstants = JitsiMeetJS.constants.recording;
export const JitsiSIPVideoGWStatus = JitsiMeetJS.constants.sipVideoGW;
export const JitsiTrackErrors = JitsiMeetJS.errors.track;
export const JitsiTrackEvents = JitsiMeetJS.events.track;
export const JitsiMediaDevices = JitsiMeetJS.mediaDevices;
export const createLocalTracks = JitsiMeetJS.createLocalTracks;

let room = null;
let jitsiConnection = null;
let localAudioTrack = null;
let isleaving = false;
let localDesktopAudioTrack = null;
const dispatch = store.dispatch;

export const MEDIA_TYPE = {
  AUDIO: "audio",
  PRESENTER: "presenter",
  SCREENSHARE: "screenshare",
  VIDEO: "video",
};

export function GetRoom() {
  return room;
}

export function reconnect(roomName, userName, userId, _updateCall) {
  const { microphoneDevice, cameraDevice, virtualBackgroundOptions } =
    store.getState().lib;
  const { callDetail } = store.getState().call;

  connect(roomName)
    .then((connection) => {
      joinRoom(connection, roomName, userName, userId, _updateCall)
        .then((room) => {
          const { callDetail } = store.getState().call;
          store.dispatch(setLocalJid(room.myUserId()));
          if (userId === callDetail?.senderID) {
            updateCallParticipantID("jid", room.myUserId());
          }
          if (
            callDetail?.monitor_status === undefined ||
            callDetail?.monitor_status === false
          ) {
            createAudioTracks(microphoneDevice)
              .then((track) => {
                const trackData = {
                  track: track[0],
                  device: microphoneDevice,
                };
                if (track && track[0] && !track[0]?.isMuted()) {
                  track[0]?.mute();
                }
                store.dispatch(addLocalAudioTrack(trackData));
                LocaltrackReceive(track[0]);
                store.dispatch(setisActiveMic(false));
                store.dispatch(setisAvailableMic(true));
                store.dispatch(setisActiveSpeaker(true));
              })
              .catch((error) => {
                throw error;
              });
            createVideoTracks(cameraDevice)
              .then((track) => {
                // JitsiMeetJS.setSenderVideoConstraint("720");
                if (Object.keys(virtualBackgroundOptions).length > 0) {
                  toggleBackgroundEffect(
                    virtualBackgroundOptions,
                    track[0],
                    "init",
                  );
                }
                const trackData = {
                  track: track[0],
                  device: cameraDevice,
                };

                localVideoTrackAdd(trackData);
                LocaltrackReceive(track[0]);
                store.dispatch(setisActiveCamera(true));
                store.dispatch(setisAvailableCamera(true));
              })
              .catch((error) => {
                throw error;
              });
          }

          if (callDetail?.type === "group") {
            store.dispatch(setiframeLoading(false));
            store.dispatch(setvideoContainerVisible("inherit"));
          }
        })
        .catch((err) => {
          console.log("JOIN ROOM FAILED", err);
        });
    })
    .catch((err) => {
      console.log("CONNECT FAILED", err);
    });
}

export function init(roomName, userName, userId, _updateCall) {
  const { microphoneDevice, cameraDevice, virtualBackgroundOptions } =
    store.getState().lib;
  const { callDetail } = store.getState().call;

  connect(roomName)
    .then((connection) => {
      joinRoom(connection, roomName, userName, userId, _updateCall)
        .then((room) => {
          const { callDetail } = store.getState().call;
          store.dispatch(setLocalJid(room.myUserId()));
          if (userId === callDetail?.senderID) {
            updateCallParticipantID("jid", room.myUserId());
          }
          if (
            callDetail?.monitor_status === undefined ||
            callDetail?.monitor_status === false
          ) {
            createAudioTracks(microphoneDevice)
              .then((track) => {
                const trackData = {
                  track: track[0],
                  device: microphoneDevice,
                };
                if (track && track[0] && !track[0]?.isMuted()) {
                  track[0]?.mute();
                }
                store.dispatch(addLocalAudioTrack(trackData));
                LocaltrackReceive(track[0]);
                store.dispatch(setisActiveMic(false));
                store.dispatch(setisAvailableMic(true));
                store.dispatch(setisActiveSpeaker(true));
              })
              .catch((error) => {
                throw error;
              });
            createVideoTracks(cameraDevice)
              .then((track) => {
                // JitsiMeetJS.setSenderVideoConstraint("720");
                if (Object.keys(virtualBackgroundOptions).length > 0) {
                  toggleBackgroundEffect(
                    virtualBackgroundOptions,
                    track[0],
                    "init",
                  );
                }
                const trackData = {
                  track: track[0],
                  device: cameraDevice,
                };

                localVideoTrackAdd(trackData);
                LocaltrackReceive(track[0]);
                store.dispatch(setisActiveCamera(true));
                store.dispatch(setisAvailableCamera(true));
              })
              .catch((error) => {
                throw error;
              });
          }

          if (callDetail?.type === "group") {
            store.dispatch(setiframeLoading(false));
            store.dispatch(setvideoContainerVisible("inherit"));
          }
        })
        .catch((err) => {
          console.log("JOIN ROOM FAILED", err);
        });
    })
    .catch((err) => {
      if (callDetail?.monitor_status) {
        reconnect(roomName, userName, userId, _updateCall);
      }
    });
}
export async function permissionPrompt() {}

export async function createInitialTracks() {
  const initOptions = {
    disableAudioLevels: false,
  };

  JitsiMeetJS.init(initOptions);
  let options = {
    devices: ["audio", "video"],
    firePermissionPromptIsShownEvent: true,
    fireSlowPromiseEvent: true,
  };
  return JitsiMeetJS.createLocalTracks(options).catch((error) => {
    return Promise.reject(error);
  });
}

export async function createTracks() {
  const initOptions = {
    disableAudioLevels: false,
  };

  JitsiMeetJS.init(initOptions);
  let options = {
    devices: ["audio", "video"],
    firePermissionPromptIsShownEvent: true,
    fireSlowPromiseEvent: true,
  };
  return JitsiMeetJS.createLocalTracks(options).catch((error) => {
    return Promise.reject(error);
  });
}

export async function createVideoTracks(cameraDeviceId) {
  const initOptions = {
    disableAudioLevels: false,
  };
  JitsiMeetJS.init(initOptions);
  let options = {
    devices: ["video"],
    firePermissionPromptIsShownEvent: true,
    fireSlowPromiseEvent: true,
    resolution: 720,
  };
  if (typeof cameraDeviceId !== "undefined" && cameraDeviceId !== null) {
    options.cameraDeviceId = cameraDeviceId;
  }
  return JitsiMeetJS.createLocalTracks(options).catch((error) => {
    return Promise.reject(error);
  });
}
export async function createAudioTracks(micDeviceId) {
  // const initOptions = {
  //   disableAudioLevels: true,
  // };
  // JitsiMeetJS.init(initOptions);
  let options = {
    devices: ["audio"],
    firePermissionPromptIsShownEvent: true,
    fireSlowPromiseEvent: true,
  };
  if (typeof micDeviceId !== "undefined" && micDeviceId !== null) {
    options.micDeviceId = micDeviceId;
  }

  return JitsiMeetJS.createLocalTracks(options).catch((error) => {
    return Promise.reject(error);
  });
}

export async function createDesktopTrack() {
  let options = {
    devices: ["desktop"],
    firePermissionPromptIsShownEvent: true,
    fireSlowPromiseEvent: true,
  };
  return JitsiMeetJS.createLocalTracks(options).catch((error) => {
    return Promise.reject(error);
  });
}

export function getAvailableDevices() {
  return new Promise((resolve) => {
    const { mediaDevices } = JitsiMeetJS;

    if (
      mediaDevices.isDeviceListAvailable() &&
      mediaDevices.isDeviceChangeAvailable()
    ) {
      mediaDevices.enumerateDevices((devices) => {
        resolve(devices);
      });
    } else {
      resolve([]);
    }
  });
}

export function groupDevicesByKind(devices) {
  return {
    audioInput: devices.filter((device) => device.kind === "audioinput"),
    audioOutput: devices.filter((device) => device.kind === "audiooutput"),
    videoInput: devices.filter((device) => device.kind === "videoinput"),
  };
}

export function isFatalJitsiConnectionError(error) {
  if (typeof error !== "string") {
    error = error.name; // eslint-disable-line no-param-reassign
  }

  return (
    error === JitsiConnectionErrors.CONNECTION_DROPPED_ERROR ||
    error === JitsiConnectionErrors.OTHER_ERROR ||
    error === JitsiConnectionErrors.SERVER_ERROR
  );
}

export function initdevices(d) {
  JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.DEBUG);

  const dispatch = d;
  const initOptions = {
    disableAudioLevels: false,
    disableSimulcast: false,
  };

  JitsiMeetJS.init(initOptions);
  getAvailableDevices().then((getAvailableDevice) => {
    Promise.all([
      JitsiMediaDevices.isDevicePermissionGranted("audio"),
      JitsiMediaDevices.isDevicePermissionGranted("video"),
    ]).then((r) => {
      const [hasAudioPermission, hasVideoPermission] = r;
      dispatch(setHasAudioPermission(hasAudioPermission));
      dispatch(setHasVideoPermission(hasVideoPermission));
    });
    let devices = groupDevicesByKind(getAvailableDevice);
    dispatch(
      setMicrophoneDeviceList(JSON.parse(JSON.stringify(devices.audioInput))),
    );
    dispatch(
      setCameraDeviceList(JSON.parse(JSON.stringify(devices.videoInput))),
    );
    dispatch(
      setSpeakerDeviceList(JSON.parse(JSON.stringify(devices.audioOutput))),
    );

    JitsiMediaDevices.addEventListener(
      JitsiMediaDevicesEvents.DEVICE_LIST_CHANGED,
      deviceChangeListener,
    );
    checkAvSettingExist();
  });
}

const deviceChangeListener = (availableDevices) => {
  let devices = groupDevicesByKind(availableDevices);
  store.dispatch(
    setMicrophoneDeviceList(JSON.parse(JSON.stringify(devices.audioInput))),
  );
  store.dispatch(
    setCameraDeviceList(JSON.parse(JSON.stringify(devices.videoInput))),
  );
  store.dispatch(
    setSpeakerDeviceList(JSON.parse(JSON.stringify(devices.audioOutput))),
  );
  // let label = devices.audioOutput[0].label;
  // if (label === "") {
  //   let audioDevice = JSON.parse(JSON.stringify(devices.audioOutput))[0];
  //   audioDevice.label = "Default";
  //   store.dispatch(setSpeakerDevice(audioDevice?.deviceId));
  // } else {
  //   store.dispatch(
  //     setSpeakerDevice(
  //       JSON.parse(JSON.stringify(devices.audioOutput))[0]?.deviceId,
  //     ),
  //   );
  // }
  // dispatch(setmicDevice(JSON.parse(JSON.stringify(devices.audioInput))[0]));
  // dispatch(setcameraDevice(JSON.parse(JSON.stringify(devices.videoInput))[0]));
  Promise.all([
    JitsiMediaDevices.isDevicePermissionGranted("audio"),
    JitsiMediaDevices.isDevicePermissionGranted("video"),
  ]).then((r) => {
    const [hasAudioPermission, hasVideoPermission] = r;
    store.dispatch(setHasAudioPermission(hasAudioPermission));
    store.dispatch(setHasVideoPermission(hasVideoPermission));
  });

  // let micid = devices["audioInput"][0]["deviceId"]
  // let videoid = devices["videoInput"][0]["deviceId"]
  // let audioid = devices["audioOutput"][0]["deviceId"]
  //updateAVSettings(micid, videoid, audioid);
};

export async function connect(roomName) {
  // JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);

  let serviceUrl = config.websocket || config.bosh;

  serviceUrl += `?room=${roomName}`;

  config.serviceUrl = serviceUrl;

  if (config.websocketKeepAliveUrl) {
    config.websocketKeepAliveUrl += `?room=${roomName}`;
  }

  const initOptions = {
    disableAudioLevels: false,
    disableSimulcast: false,
  };

  JitsiMeetJS.init(initOptions);
  JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.DEBUG);
  const connection = new JitsiMeetJS.JitsiConnection(null, null, config);
  jitsiConnection = connection;
  return new Promise((resolve, reject) => {
    connection.addEventListener(
      JitsiConnectionEvents.CONNECTION_ESTABLISHED,
      handleConnectionEstablished,
    );
    connection.addEventListener(
      JitsiConnectionEvents.CONNECTION_FAILED,
      handleConnectionFailed,
    );

    connection.addEventListener(
      JitsiConnectionEvents.CONNECTION_DISCONNECTED,
      handleConnectionDisconnect,
    );

    connection.connect();

    function unsubscribe() {
      connection.removeEventListener(
        JitsiConnectionEvents.CONNECTION_ESTABLISHED,
        handleConnectionEstablished,
      );
      connection.removeEventListener(
        JitsiConnectionEvents.CONNECTION_FAILED,
        handleConnectionFailed,
      );
    }

    function handleConnectionEstablished() {
      unsubscribe();
      resolve(connection);
    }

    function handleConnectionFailed(err) {
      unsubscribe();
      reject(err);
    }

    function handleConnectionDisconnect(err) {
      reject(err);
    }
  });
}

export async function joinRoom(
  connection,
  roomName,
  userName,
  userID,
  _updateCall,
) {
  room = connection.initJitsiConference(roomName, config);

  return new Promise((resolve) => {
    roomevents(room, userName, userID, _updateCall, roomName);
    resolve(room);
  });
}
const roomevents = (room, userName, userID, _updateCall, roomName) => {
  room.on(JitsiConferenceEvents.TRACK_ADDED, (track) => {
    if (track.isLocal()) {
      return;
    }

    let noDataFromSourceNotificationInfo;
    let trackdata = {
      trackID: track.getId(),
      isReceivingData: true,
      mediaType: track.videoType,
      muted: track.isMuted(),
      noDataFromSourceNotificationInfo,
      participantId: track.getParticipantId(),
      videoStarted: false,
      type: track.type,
    };
    dispatch(trackAdd(track));

    track.on(JitsiTrackEvents.TRACK_MUTE_CHANGED, (track) => {
      dispatch(trackMuteUpdate(track));
    });
    track.on(JitsiTrackEvents.TRACK_VIDEOTYPE_CHANGED, () => {
      dispatch(trackMediaTypeUpdate(track));
    });
    if (track.type === "video") {
      // changeTrackResolution();
    }
  });
  room.on(JitsiConferenceEvents.TRACK_REMOVED, (track) => {
    dispatch(trackRemove(track));
    // onremotetrackRemove(track);
  });

  room.on(JitsiConferenceEvents.CONFERENCE_JOINED, () => {
    console.log("CONFERENCE_JOINED");
  });

  room.on(JitsiConferenceEvents.USER_JOINED, (id, user) => {
    const state = store.getState();
    const { callDetail } = state.call;

    let displayNameArrayData = null;
    if (user._displayName !== null) {
      displayNameArrayData = user._displayName?.split("_");
    }

    if (user.isHidden()) {
      return;
    }

    if (
      displayNameArrayData !== null &&
      displayNameArrayData[0] === "RECORDER"
    ) {
      return;
    }
    dispatch(partcipantAdd(user));
    if (callDetail?.type !== "group") {
      store.dispatch(setiframeLoading(false));
      store.dispatch(setvideoContainerVisible("inherit"));
    }

    // if (user._displayName !== "RECORDER") {
    //   store.dispatch(addnumpartcipient());
    // }
    // store.dispatch(setiframeLoading(false));
    // remoteTracks[id] = [];
  });
  room.on(JitsiConferenceEvents.USER_LEFT, (id, user) => {
    dispatch(partcipantRemove(user));
  });
  room.on(JitsiConferenceEvents.DISPLAY_NAME_CHANGED, (userID, displayName) => {
    console.info(`${userID} - ${displayName}`);
    console.info(`${userID} - ${displayName}`);
  });
  // room.on(
  //   JitsiConferenceEvents.TRACK_AUDIO_LEVEL_CHANGED,
  //   (userID, audioLevel) => console.info(`${userID} - ${audioLevel}`),
  // );
  room.on(JitsiConferenceEvents.RECORDER_STATE_CHANGED, (recorderSession) => {
    console.log(
      "RECORDER_STATE_CHANGED",
      recorderSession,
      recorderSession.getID(),
    );
    if (recorderSession) {
      recorderSession.getID() &&
        updateRecordingSessionData(recorderSession, _updateCall);
      recorderSession.getError() &&
        _showRecordingErrorNotification(recorderSession);
    }

    // if (recorderSession) {
    //   updateRecordingSessionData(recorderSession, _updateCall);
    //   recorderSession.getError() &&
    //     _showRecordingErrorNotification(recorderSession);
    // }
  });
  room.on(
    JitsiConferenceEvents.DOMINANT_SPEAKER_CHANGED,
    (id, previousSpeakers) => {
      console.info("Dominent speaker change =>", id, previousSpeakers);
      dominentSpeakerchange(id, previousSpeakers);
      updateLastSpeakerTime(id);
    },
  );
  room.setSenderVideoConstraint("2160");
  room.join();
  room.setDisplayName(`${userName}_${userID}`);
};

const dominentSpeakerchange = (id) => {
  dispatch(activeSpeakerChange(id));
};
// export const changeTrackResolution = () => {
//   const tracks = store.getState().lib.tracks;

//   const participantIdArray = [];
//   tracks.forEach((t) => {
//     if (t.type === "video")
//       participantIdArray.push(t.jitsiTrack.getSourceName());
//   });
//   if (participantIdArray.length > 0) {
//     let height = "180";
//     const length = participantIdArray.length;
//     if (length <= 4) {
//       height = "1080";
//     }
//     let receiverConstraints = {
//       constraints: {},
//       defaultConstraints: { maxHeight: 0 },
//       lastN: config.channelLastN,
//       onStageSources: [],
//       selectedSources: [],
//     };
//     participantIdArray.forEach((participantId) => {
//       receiverConstraints.constraints[participantId] = { maxHeight: height };
//     });
//     receiverConstraints.onStageSources = participantIdArray;
//     receiverConstraints.selectedSources = participantIdArray;
//     console.info("receiverConstraints", JSON.stringify(receiverConstraints));
//     if (room?.setReceiverConstraints) {
//       try {
//         room.setReceiverConstraints(receiverConstraints);
//       } catch (error) {
//         console.error(
//           error,
//           `Failed to set receiver video constraints ${JSON.stringify(
//             receiverConstraints,
//           )}`,
//         );
//       }
//     }
//   }
// };

export const changeTrackResolution = (participantId) => {
  if (participantId.length > 0) {
    let height = "180";
    const length = participantId.length;
    if (length <= 2) {
      height = "720";
    }
    let receiverConstraints = {
      constraints: {},
      defaultConstraints: { maxHeight: 0 },
      lastN: config.channelLastN,
      onStageSources: [],
      selectedSources: [],
    };
    participantId.forEach((participantId) => {
      receiverConstraints.constraints[participantId] = { maxHeight: height };
    });
    // receiverConstraints.onStageSources = participantId;
    receiverConstraints.selectedSources = participantId;
    console.info("receiverConstraints", JSON.stringify(receiverConstraints));
    if (room?.setReceiverConstraints) {
      try {
        room.setReceiverConstraints(receiverConstraints);
      } catch (error) {
        console.error(
          error,
          `Failed to set receiver video constraints ${JSON.stringify(
            receiverConstraints,
          )}`,
        );
      }
    }
  }
};

// export const changeTrackResolutionActiveSpeaker = async (pinUserId) => {
//   const tracks = store.getState().lib.tracks;
//   const participantIdArray = [];
//   tracks.forEach((t) => {
//     if (pinUserId === `${t?.participantId}screen`) {
//       if (t.type === "video" && t.mediaType === "desktop") {
//         participantIdArray.push(t.jitsiTrack.getSourceName());
//       }
//     } else if (pinUserId === `${t?.participantId}Video`) {
//       if (t.type === "video" && t.mediaType === "camera") {
//         participantIdArray.push(t.jitsiTrack.getSourceName());
//       }
//     }
//   });
//   if (participantIdArray.length > 0) {
//     const receiverConstraints = {
//       constraints: {},
//       defaultConstraints: { maxHeight: 0 },
//       lastN: config.channelLastN,
//       onStageSources: [],
//       selectedSources: [],
//     };
//     let i = 0;
//     participantIdArray.forEach((p) => {
//       if (i === 0) {
//         receiverConstraints.constraints[p] = { maxHeight: "1080" };
//         i++;
//       } else {
//         receiverConstraints.constraints[p] = { maxHeight: "180" };
//       }
//     });
//     receiverConstraints.onStageSources = participantIdArray;
//     receiverConstraints.selectedSources = participantIdArray;
//     console.info(
//       "receiverConstraints changeTrackResolutionActiveSpeaker",
//       JSON.stringify(receiverConstraints),
//     );
//     try {
//       room.setReceiverConstraints(receiverConstraints);
//     } catch (error) {
//       console.error(
//         error,
//         `Failed to set receiver video constraints ${JSON.stringify(
//           receiverConstraints,
//         )}`,
//       );
//     }
//   }
// };

export const changeTrackResolutionActiveSpeaker = (participantId) => {
  if (participantId.length > 0) {
    let height = "180";
    const length = participantId.length;
    if (length <= 2) {
      height = "1080";
    }
    let receiverConstraints = {
      constraints: {},
      defaultConstraints: { maxHeight: 0 },
      lastN: config.channelLastN,
      onStageSources: [],
      selectedSources: [],
    };
    participantId.forEach((participantId) => {
      receiverConstraints.constraints[participantId] = { maxHeight: height };
    });
    receiverConstraints.onStageSources = participantId;
    console.info(
      "receiverConstraints changeTrackResolutionActiveSpeaker",
      JSON.stringify(receiverConstraints),
    );
    if (room?.setReceiverConstraints) {
      try {
        room.setReceiverConstraints(receiverConstraints);
      } catch (error) {
        console.error(
          error,
          `Failed to set receiver video constraints ${JSON.stringify(
            receiverConstraints,
          )}`,
        );
      }
    }
  }
};

export function localVideoTrackMute() {
  const tracks = store.getState().lib.tracks;
  const { localVideoTrack: localVideoTrackLib } = store.getState().lib;
  if (localVideoTrackLib?.jitsiTrack) {
    console.debug("Muting existing local video track.");
    localVideoTrackLib.jitsiTrack.mute();
    store.dispatch(setisActiveCamera(false));
    store.dispatch(trackMuteUpdateLocal(localVideoTrackLib.jitsiTrack));
    store.dispatch(updateLocalVideoTrackMute(true));
  }
}
export function localVideoTrackUnMute() {
  const { tracks, virtualBackgroundOptions, cameraDevice } =
    store.getState().lib;
  const { localVideoTrack: localVideoTrackLib } = store.getState().lib;
  console.debug("Trying to create local video track.");
  createVideoTracks(cameraDevice)
    .then((track) => {
      if (Object.keys(virtualBackgroundOptions).length > 0) {
        toggleBackgroundEffect(
          virtualBackgroundOptions,
          track[0],
          "localVideoTrackUnMute",
        );
      }
      let trackData = {
        track: track[0],
        device: cameraDevice,
      };
      if (localVideoTrackLib?.jitsiTrack) {
        room.replaceTrack(localVideoTrackLib?.jitsiTrack, track[0]).then(() => {
          localVideoTrackAdd(trackData);
        });
        store.dispatch(trackRemove(localVideoTrackLib?.jitsiTrack));
      } else {
        room?.addTrack(track[0]);
        localVideoTrackAdd(trackData);
      }
      store.dispatch(trackAddLocal(track[0]));
      store.dispatch(setisActiveCamera(true));
      store.dispatch(setisAvailableCamera(true));
      store.dispatch(setcameraDeviceId(track[0].deviceId));
    })
    .catch((error) => {
      throw error;
    });
}

export function localAudioTrackMute() {
  const { localAudioTrack: localAudioTrackLib } = store.getState().lib;
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    if (localAudioTrackLib && !localAudioTrackLib?.jitsiTrack?.isMuted()) {
      console.debug("Muting existing local audio track.");
      await localAudioTrackLib.jitsiTrack.mute();
      resolve({ audio: true });
    } else {
      resolve({ audio: false });
    }
  });
}

export function localAudioTrackUnMute() {
  const { microphoneDevice, localAudioTrack: localAudioTrackLib } =
    store.getState().lib;
  if (localAudioTrackLib?.jitsiTrack) {
    console.debug("Unmuting existing local audio track.");
    localAudioTrackLib?.jitsiTrack.unmute();
    store.dispatch(setisActiveMic(true));
    updateCallParticipantID("audio", true);
  } else {
    console.debug("Trying to create local audio track.");

    createAudioTracks()
      .then(async (track) => {
        localAudioTrack = track[0];
        if (localDesktopAudioTrack !== null) {
          const localAudio = localAudioTrackLib;
          room.replaceTrack(localDesktopAudioTrack, track[0]).then(() => {
            const trackData = {
              track: track[0],
              device: microphoneDevice,
            };
            store.dispatch(addLocalAudioTrack(trackData));
          });
          let mixerEffect = new AudioMixerEffect(localDesktopAudioTrack);
          console.debug(
            `_switchToScreenSharing is mixing ${localDesktopAudioTrack} and ${localAudio}` +
              " as a single audio stream",
          );
          await localAudio.setEffect(mixerEffect);
        } else {
          room?.addTrack(track[0]);
          const trackData = {
            track: track[0],
            device: microphoneDevice,
          };
          store.dispatch(addLocalAudioTrack(trackData));
        }

        localAudioTrack = track[0];
        store.dispatch(setisActiveMic(true));
        updateCallParticipantID("audio", true);
        store.dispatch(setisAvailableMic(true));
      })
      .catch((error) => {
        throw error;
      });
  }
}

export function setAudioOutputDevice(deviceId) {
  if (deviceId) {
    JitsiMediaDevices.setAudioOutputDevice(deviceId);
  }
}

export function speakerDeviceChange(speakerDevice) {
  if (speakerDevice) {
    setAudioOutputDevice(speakerDevice.deviceId);
  }
}

export async function filterTracksByVideoType(tracks, videotype) {
  const track = Object.keys(tracks).filter(
    (tra) =>
      tracks[tra].getType() === "video" &&
      tracks[tra].getVideoType() === videotype,
  );
  if (tracks[track] === undefined) return null;
  else return tracks[track];
}

export async function filterTracksByType(tracks, type) {
  let track = [];
  for (let i = 0, j = 0; i < tracks.length; i++) {
    if (tracks[i].getType() === type) {
      track[j] = tracks[i];
      j++;
    }
  }
  if (track.length === 0) return null;
  else return track;
}

export const LocaltrackReceive = (track) => {
  if (track.getType() === "video") {
    // localVideoTrack = track;
    // localVideo(track);
    store.dispatch(setcameraDeviceId(track?.deviceId));
    store.dispatch(trackAddLocal(track));
  } else {
    localAudioTrack = track;
    store.dispatch(setmicDeviceId(track?.deviceId));
  }
  if (room != null) {
    room?.addTrack(track);
  }
};

export const startscreenshare = async () => {
  let localDesktopVideoTrack = store.getState().lib.localDesktopVideoTrack;
  const authSessionUser = store.getState().authUserDetail.authSessionUser;
  const allAttendeeList = store.getState().call.allAttendeeList;
  const myUserId = authSessionUser?.user_id ?? "";
  const userData = allAttendeeList.find(
    (filterItem) => filterItem.user_id === myUserId,
  );
  console.debug("userData=>", userData, allAttendeeList);
  createDesktopTrack()
    .then(async (desktoptrack) => {
      const desktopVideoStream = desktoptrack.find(
        (stream) => stream.getType() === MEDIA_TYPE.VIDEO,
      );
      const desktopAudioStream = desktoptrack.find(
        (stream) => stream.getType() === MEDIA_TYPE.AUDIO,
      );
      if (desktopAudioStream) {
        localDesktopAudioTrack = desktopAudioStream;
        desktopAudioStream.on(
          JitsiTrackEvents.LOCAL_TRACK_STOPPED,
          async () => {
            console.debug("Local screensharing audio track stopped.");
            if (localAudioTrack !== null) {
              await localAudioTrack.setEffect(undefined);
              if (localDesktopAudioTrack !== null) {
                await localDesktopAudioTrack.dispose();
              }
            } else if (localDesktopAudioTrack !== null) {
              await room.replaceTrack(localDesktopAudioTrack, null);
              localDesktopAudioTrack.dispose();
            }
            localDesktopAudioTrack = null;
          },
        );
        const localAudio = localAudioTrack;
        if (localAudio !== null) {
          let mixerEffect = new AudioMixerEffect(desktopAudioStream);
          console.debug(
            `_switchToScreenSharing is mixing ${desktopAudioStream} and ${localAudio}` +
              " as a single audio stream",
          );
          await localAudio.setEffect(mixerEffect);
        } else {
          console.debug(
            `_switchToScreenSharing is using ${desktopAudioStream} for replacing it as` +
              " the only audio track on the conference",
          );
          await room.replaceTrack(null, desktopAudioStream);
        }
      }
      desktopVideoStream.on(JitsiTrackEvents.LOCAL_TRACK_STOPPED, () => {
        console.debug("Local screensharing track stopped. ");
        stopscreenshare();
      });
      console.debug(
        "desktoptrack=>  logSocketConsole===>",
        desktopVideoStream.sourceType,
      );
      const updatedLoggedInUserData = { ...userData };
      updatedLoggedInUserData["screenShareSource"] =
        desktopVideoStream.sourceType;
      _userUpdate(updatedLoggedInUserData);

      dispatch(addLocalDesktopVideoTrack(desktopVideoStream));
      desktoptrack = desktopVideoStream;
      store.dispatch(setisActiveScreenSharing(true));
      updateCallParticipantID("screenShare", true);
      if (localDesktopVideoTrack !== null) {
        room.replaceTrack(
          localDesktopVideoTrack.jitsiTrack,
          desktopVideoStream,
        );
      } else {
        room?.addTrack(desktopVideoStream);
      }
    })
    .catch((error) => {
      throw error;
    });
};

export const stopscreenshare = async () => {
  store.dispatch(setisActiveScreenSharing(false));

  store.dispatch(setSharedScreenType(""));
  updateScreenshareTypeGlobally("", "stop");
  let localDesktopVideoTrack = store.getState().lib.localDesktopVideoTrack;
  if (localDesktopVideoTrack !== null) {
    localDesktopVideoTrack.jitsiTrack.mute();
    dispatch(muteLocalDesktopVideoTrack(localDesktopVideoTrack.jitsiTrack));
  }

  if (localAudioTrack !== null) {
    await localAudioTrack.setEffect(undefined);
    if (localDesktopAudioTrack !== null) {
      await localDesktopAudioTrack.dispose();
    }
  } else if (localDesktopAudioTrack !== null) {
    await room.replaceTrack(localDesktopAudioTrack, null);
    localDesktopAudioTrack.dispose();
  }
  localDesktopAudioTrack = null;
  updateCallParticipantID("screenShare", false);
};

export function startrecording() {
  if (!room) {
    console.error("Conference is not defined");

    return;
  }
  store.dispatch(setrecordingLoader(true));
  let recordingConfig = {
    mode: JitsiRecordingConstants.mode.FILE,
    appData: JSON.stringify({
      file_recording_metadata: {
        share: false,
      },
    }),
  };
  // const recordingID = uuidv4();
  // let recordingConfig = {
  //   mode: "stream",
  //   streamId: `rtmp://135.148.168.72/app/${recordingID}`,
  // };
  room.startRecording(recordingConfig);
  // setTimeout(async () => {
  //   const recordingPayload = { id: recordingID, stream: { name: recordingID } };
  //   const res = await startRecordingApi(recordingPayload);
  //   console.log("startRecordingApi", res);
  //   if (res.status == true) {
  //     store.dispatch(setRecordingID(recordingID));
  //     store.dispatch(setisActiveRecording(true));
  //     store.dispatch(setrecordingLoader(false));
  //   } else {
  //     store.dispatch(setRecordingID(""));
  //   }
  // }, 5000);
}

export async function stoprecording() {
  if (!room) {
    console.error("Conference is not defined");

    return;
  }

  const recorderActiveSessionid = store.getState().lib.recorderActiveSessionid;
  const isActiveRecording = store.getState().lib.isActiveRecording;
  // const { recordingID } = store.getState().call;
  if (isActiveRecording && recorderActiveSessionid) {
    room.stopRecording(recorderActiveSessionid);
    // setTimeout(async () => {
    //   const recordingPayload = {
    //     id: recordingID,
    //   };
    //   const res = await stopRecordingApi(recordingPayload);
    //   console.log("stopRecordingApi", res);
    //   if (res.status == true) {
    //     store.dispatch(setRecordingID(""));
    //     store.dispatch(setisActiveRecording(false));
    //   }
    // }, 5000);
  } else {
    console.error("No recording session found");
  }
}

function updateRecordingSessionData(recorderSession, _updateCall) {
  // const status = recorderSession.getStatus();
  const status = recorderSession?._statusFromJicofo;
  const recorderid = recorderSession.getID();

  if (status === "") {
    store.dispatch(setrecordingLoader(true));
  }
  if (status === "on") {
    store.dispatch(setrecordingLoader(false));
    store.dispatch(setisActiveRecording(true));
    store.dispatch(setrecorderActiveSessionid(recorderid));
    // // if (
    // //   store.getState().call.callDetail.senderID ===
    // //   store.getState().authUserDetail.authSessionUser.user_id
    // // ) {
    // //   const recordingTimestamp = Date.now();
    // //   const UpdateCallDetail = { ...store.getState().call.callDetail };
    // //   UpdateCallDetail["recordingTimestamp"] = recordingTimestamp;
    // //   _updateCall(UpdateCallDetail);
    // // }
    // if (store.getState().lib.recordingInterval === null) {
    //   const timestamp =
    //     store?.getState()?.call?.callDetail?.recordingTimestamp ?? null;
    //   var seconds = 0;
    //   if (timestamp != null) {
    //     const now = moment(Date.now());
    //     var end = moment(timestamp);
    //     seconds = now.diff(end, "seconds");
    //   }
    //   let recordingIntervalData = setInterval(() => {
    //     store.dispatch(
    //       setrecordingTime(
    //         store.getState().lib.recordingTime === 0
    //           ? seconds + 1
    //           : store.getState().lib.recordingTime + 1,
    //       ),
    //     );
    //   }, [1000]);
    //   store.dispatch(setRecordingInterval(recordingIntervalData));
    // }
  }
  if (status === "off") {
    store.dispatch(setrecordingLoader(false));
    store.dispatch(setisActiveRecording(false));
    store.dispatch(setrecorderActiveSessionid(null));
    // if (
    //   store.getState().call.callDetail.senderID ===
    //   store.getState().authUserDetail.authSessionUser.user_id
    // ) {
    //   const UpdateCallDetail = { ...store.getState().call.callDetail };
    //   UpdateCallDetail["recordingTimestamp"] = null;
    //   _updateCall(UpdateCallDetail);
    // }
    // if (store.getState().lib.recordingInterval != null) {
    //   clearInterval(store.getState().lib.recordingInterval);
    //   store.dispatch(setRecordingInterval(null));
    //   store.dispatch(setrecordingTime(0));
    // }
  }
}

function _showRecordingErrorNotification(recorderSession) {
  const error = recorderSession.getError();
  switch (error) {
    case JitsiMeetJS.constants.recording.error.SERVICE_UNAVAILABLE:
      console.error("Recorder Unavailable");
      break;
    case JitsiMeetJS.constants.recording.error.RESOURCE_CONSTRAINT:
      console.error("All recorders are busy");
      break;
    case JitsiMeetJS.constants.recording.error.UNEXPECTED_REQUEST:
      console.error("Recorder already active");
      break;
    default:
      console.error("Failed to start recording");
      break;
  }
  store.dispatch(setrecordingLoader(false));
  store.dispatch(setisActiveRecording(false));
  store.dispatch(setrecorderActiveSessionid(null));
}

export const getAudioTracks = () => {
  const { tracks } = store.getState().lib;
  let audioTracks = [];
  tracks.forEach((t) => {
    if (t.type === "audio" && t.participantId !== "local") {
      audioTracks.push(t);
    }
  });
  return audioTracks;
};
export const getVideoTracks = (tracks) => {
  let videoTracks = [];
  tracks.forEach((t) => {
    if (t.type === "video" && t.mediaType === "camera" && t.muted === false) {
      videoTracks.push(t);
    }
  });
  return videoTracks;
};

export const getParticipiantsVideoTracks = (jid) => {
  const state = store.getState();
  const { tracks } = state.lib;
  const videoTracks = tracks.find(
    (t) =>
      t.participantId === jid && t.type === "video" && t.mediaType === "camera",
  );
  return videoTracks;
};

export const getParticipiantsDesktopTracks = (jid, type) => {
  const state = store.getState();
  const { tracks } = state.lib;
  const desktopTracks = tracks.find(
    (t) =>
      t.participantId === jid &&
      t.type === "video" &&
      t.mediaType === "desktop",
  );
  return desktopTracks;
};

export const getLocalVideoTrack = (tracks) => {
  let videoTracks = [];
  tracks.forEach((t) => {
    if (
      t.participantId === "local" &&
      t.type === "video" &&
      t.mediaType === "camera"
    ) {
      videoTracks.push(t);
    }
  });
  return videoTracks;
};
export const getLocalAudioTrack = (tracks) => {
  let audioTracks = [];
  tracks.forEach((t) => {
    if (t.participantId === "local" && t.type === "audio") {
      audioTracks.push(t);
    }
  });
  return audioTracks;
};

export async function replaceLocalVideoTrack(oldtrack, newtrack) {
  return new Promise((resolve) => {
    room.replaceTrack(oldtrack, newtrack).then(() => {
      resolve();
    });
  });
}

export async function replaceLocalAudioTrack(oldtrack, newtrack) {
  return new Promise((resolve) => {
    room.replaceTrack(oldtrack, newtrack).then(() => {
      resolve();
    });
  });
}

export async function localVideoTrackAdd(localTrack) {
  console.log("localVideoTrackAdd localTrack", localTrack);
  const track = localTrack?.track;
  if (track) {
    track.on(JitsiTrackEvents.LOCAL_TRACK_STOPPED, () => {
      console.log("LOCAL_TRACK_STOPPED camera", track);
    });
  }
  store.dispatch(addLocalVideoTrack(localTrack));
}
export const getDesktopTracks = (tracks) => {
  let desktopTracks = [];
  tracks.forEach((t) => {
    if (t.type === "video" && t.mediaType === "desktop" && t.muted === false) {
      desktopTracks.push(t);
    }
  });
  return desktopTracks;
};

export class AudioMixerEffect {
  constructor(mixAudio) {
    if (mixAudio.getType() !== MEDIA_TYPE.AUDIO) {
      throw new Error(
        "AudioMixerEffect only supports audio JitsiLocalTracks; effect will not work!",
      );
    }

    this._mixAudio = mixAudio;
  }

  isEnabled(sourceLocalTrack) {
    return sourceLocalTrack.isAudioTrack() && this._mixAudio.isAudioTrack();
  }

  startEffect(audioStream) {
    this._originalStream = audioStream;
    this._originalTrack = audioStream.getTracks()[0];

    this._audioMixer = JitsiMeetJS.createAudioMixer();
    this._audioMixer.addMediaStream(this._mixAudio.getOriginalStream());
    this._audioMixer.addMediaStream(this._originalStream);

    this._mixedMediaStream = this._audioMixer.start();
    this._mixedMediaTrack = this._mixedMediaStream.getTracks()[0];

    return this._mixedMediaStream;
  }

  stopEffect() {
    this._audioMixer.reset();
  }

  setMuted(muted) {
    this._originalTrack.enabled = !muted;
  }

  isMuted() {
    return !this._originalTrack.enabled;
  }
}

export async function disposetrack(track) {
  return new Promise((resolve) => {
    resolve(track.dispose());
  });
}

async function disposelocaltracks() {
  const tracks = store.getState().lib.tracks;
  const localDesktopVideoTrack = store.getState().lib.localDesktopVideoTrack;
  if (localDesktopVideoTrack !== null) {
    await disposetrack(localDesktopVideoTrack.jitsiTrack);
    dispatch(removeLocalDesktopVideoTrack(null));
  }
  if (localDesktopAudioTrack !== null) {
    await disposetrack(localDesktopAudioTrack);
    localDesktopAudioTrack = null;
  }
  if (localAudioTrack !== null) {
    await disposetrack(localAudioTrack);
    localAudioTrack = null;
  }

  const localVideoTrack = getLocalVideoTrack(tracks);
  // console.log("disposelocaltracks", localVideoTrack);
  if (localVideoTrack.length > 0) {
    // localVideoTrack[0].jitsiTrack.dispose();
    await disposetrack(localVideoTrack[0].jitsiTrack);
    store.dispatch(trackRemove(localVideoTrack[0].jitsiTrack));
  }
}
export async function HangupCall(callDetails) {
  if (callDetails !== undefined) {
    if (store.getState().lib.isActiveRecording) {
      const { calling_status } = callDetails;
      if (calling_status === "decline" || calling_status === "ended") {
        await stoprecording();
      }
      store.dispatch(setisActiveRecording(false));
      store.dispatch(setRecordingID(""));
      store.dispatch(setrecorderActiveSessionid(null));
      clearInterval(store.getState().lib.recordingInterval);
      store.dispatch(setRecordingInterval(null));
      store.dispatch(setrecordingTime(0));
    }
  }
  return new Promise((resolve) => {
    if (isleaving === false) {
      isleaving = true;
      disposelocaltracks().then(() => {
        if (room !== null) {
          room
            .leave()
            .then(() => {
              room = null;
              if (jitsiConnection !== null) {
                jitsiConnection.disconnect().then(() => {
                  jitsiConnection = null;
                  store.dispatch(partcipantRemoveAll());
                  store.dispatch(setisActiveMic(false));
                  store.dispatch(setisActiveSpeaker(false));
                  store.dispatch(setisActiveCamera(false));
                  store.dispatch(setisActiveScreenSharing(false));
                  store.dispatch(setSharedScreenType(""));
                  updateScreenshareTypeGlobally("", "stop");
                  store.dispatch(setwasActiveCamera(false));
                  store.dispatch(setnumpartcipient(1));
                  store.dispatch(setiframeLoading(true));
                  store.dispatch(setvideoContainerVisible("hidden"));
                  // store.dispatch(setisActiveRecording(false));
                  store.dispatch(setrecordingLoader(false));
                  // store.dispatch(setisActiveRecording(false));
                  // store.dispatch(setrecorderActiveSessionid(null));

                  resolve();
                });
              } else {
                resolve();
              }
            })
            .catch((err) => {
              console.log("hangup init", err);
              resolve();
            });
        } else {
          resolve();
        }
        isleaving = false;
      });
    }
  });
}

export const SET_TIMEOUT = 1;
export const CLEAR_TIMEOUT = 2;
export const TIMEOUT_TICK = 3;
const code = `
    var timer;

    onmessage = function(request) {
        switch (request.data.id) {
        case ${SET_TIMEOUT}: {
            timer = setTimeout(() => {
                postMessage({ id: ${TIMEOUT_TICK} });
            }, request.data.timeMs);
            break;
        }
        case ${CLEAR_TIMEOUT}: {
            if (timer) {
                clearTimeout(timer);
            }
            break;
        }
        }
    };
`;

export const timerWorkerScript = URL.createObjectURL(
  new Blob([code], { type: "application/javascript" }),
);

export const VIRTUAL_BACKGROUND_TYPE = {
  IMAGE: "image",
  BLUR: "blur",
  NONE: "none",
};
export class JitsiStreamBackgroundEffect {
  /**
   * Represents a modified video MediaStream track.
   *
   * @class
   * @param {Object} model - Meet model.
   * @param {Object} options - Segmentation dimensions.
   */
  constructor(model, options) {
    this._options = options;

    if (
      this._options.virtualBackground.backgroundType ===
      VIRTUAL_BACKGROUND_TYPE.IMAGE
    ) {
      this._virtualImage = document.createElement("img");
      this._virtualImage.crossOrigin = "anonymous";
      this._virtualImage.src =
        this._options.virtualBackground.virtualSource ?? "";
    }
    this._model = model;
    this._segmentationPixelCount = this._options.width * this._options.height;

    // Bind event handler so it is only bound once for every instance.
    this._onMaskFrameTimer = this._onMaskFrameTimer.bind(this);

    // Workaround for FF issue https://bugzilla.mozilla.org/show_bug.cgi?id=1388974
    this._outputCanvasElement = document.createElement("canvas");
    this._outputCanvasElement.getContext("2d");
    this._inputVideoElement = document.createElement("video");
  }

  /**
   * EventHandler onmessage for the maskFrameTimerWorker WebWorker.
   *
   * @private
   * @param {EventHandler} response - The onmessage EventHandler parameter.
   * @returns {void}
   */
  _onMaskFrameTimer(response) {
    if (response.data.id === TIMEOUT_TICK) {
      this._renderMask();
    }
  }

  /**
   * Represents the run post processing.
   *
   * @returns {void}
   */
  runPostProcessing() {
    const track = this._stream.getVideoTracks()[0];
    const { height, width } = track.getSettings() ?? track.getConstraints();
    const { backgroundType } = this._options.virtualBackground;

    if (!this._outputCanvasCtx) {
      return;
    }

    this._outputCanvasElement.height = height;
    this._outputCanvasElement.width = width;
    this._outputCanvasCtx.globalCompositeOperation = "copy";

    // Draw segmentation mask.

    // Smooth out the edges.
    this._outputCanvasCtx.filter =
      backgroundType === VIRTUAL_BACKGROUND_TYPE.IMAGE
        ? "blur(4px)"
        : "blur(8px)";
    this._outputCanvasCtx?.drawImage(
      // @ts-ignore
      this._segmentationMaskCanvas,
      0,
      0,
      this._options.width,
      this._options.height,
      0,
      0,
      this._inputVideoElement.width,
      this._inputVideoElement.height,
    );
    this._outputCanvasCtx.globalCompositeOperation = "source-in";
    this._outputCanvasCtx.filter = "none";

    // Draw the foreground video.
    // @ts-ignore
    this._outputCanvasCtx?.drawImage(this._inputVideoElement, 0, 0);

    // Draw the background.
    this._outputCanvasCtx.globalCompositeOperation = "destination-over";
    if (backgroundType === VIRTUAL_BACKGROUND_TYPE.IMAGE) {
      this._outputCanvasCtx?.drawImage(
        // @ts-ignore
        backgroundType === VIRTUAL_BACKGROUND_TYPE.IMAGE
          ? this._virtualImage
          : this._virtualVideo,
        0,
        0,
        this._outputCanvasElement.width,
        this._outputCanvasElement.height,
      );
    } else {
      this._outputCanvasCtx.filter = `blur(${this._options.virtualBackground.blurValue}px)`;

      // @ts-ignore
      this._outputCanvasCtx?.drawImage(this._inputVideoElement, 0, 0);
    }
  }

  /**
   * Represents the run Tensorflow Interference.
   *
   * @returns {void}
   */
  runInference() {
    this._model._runInference();
    const outputMemoryOffset = this._model._getOutputMemoryOffset() / 4;

    for (let i = 0; i < this._segmentationPixelCount; i++) {
      const person = this._model.HEAPF32[outputMemoryOffset + i];

      // Sets only the alpha component of each pixel.
      this._segmentationMask.data[i * 4 + 3] = 255 * person;
    }
    this._segmentationMaskCtx?.putImageData(this._segmentationMask, 0, 0);
  }

  /**
   * Loop function to render the background mask.
   *
   * @private
   * @returns {void}
   */
  _renderMask() {
    this.resizeSource();
    this.runInference();
    this.runPostProcessing();

    this._maskFrameTimerWorker.postMessage({
      id: SET_TIMEOUT,
      timeMs: 1000 / 30,
    });
  }

  /**
   * Represents the resize source process.
   *
   * @returns {void}
   */
  resizeSource() {
    this._segmentationMaskCtx?.drawImage(
      // @ts-ignore
      this._inputVideoElement,
      0,
      0,
      this._inputVideoElement.width,
      this._inputVideoElement.height,
      0,
      0,
      this._options.width,
      this._options.height,
    );

    const imageData = this._segmentationMaskCtx?.getImageData(
      0,
      0,
      this._options.width,
      this._options.height,
    );
    const inputMemoryOffset = this._model._getInputMemoryOffset() / 4;

    for (let i = 0; i < this._segmentationPixelCount; i++) {
      this._model.HEAPF32[inputMemoryOffset + i * 3] =
        Number(imageData?.data[i * 4]) / 255;
      this._model.HEAPF32[inputMemoryOffset + i * 3 + 1] =
        Number(imageData?.data[i * 4 + 1]) / 255;
      this._model.HEAPF32[inputMemoryOffset + i * 3 + 2] =
        Number(imageData?.data[i * 4 + 2]) / 255;
    }
  }

  /**
   * Checks if the local track supports this effect.
   *
   * @param {JitsiLocalTrack} jitsiLocalTrack - Track to apply effect.
   * @returns {boolean} - Returns true if this effect can run on the specified track
   * false otherwise.
   */
  isEnabled(jitsiLocalTrack) {
    return (
      jitsiLocalTrack.isVideoTrack() && jitsiLocalTrack.videoType === "camera"
    );
  }

  /**
   * Starts loop to capture video frame and render the segmentation mask.
   *
   * @param {MediaStream} stream - Stream to be used for processing.
   * @returns {MediaStream} - The stream with the applied effect.
   */
  startEffect(stream) {
    this._stream = stream;
    this._maskFrameTimerWorker = new Worker(timerWorkerScript, {
      name: "Blur effect worker",
    });
    this._maskFrameTimerWorker.onmessage = this._onMaskFrameTimer;
    const firstVideoTrack = this._stream.getVideoTracks()[0];
    const { height, frameRate, width } = firstVideoTrack.getSettings
      ? firstVideoTrack.getSettings()
      : firstVideoTrack.getConstraints();

    this._segmentationMask = new ImageData(
      this._options.width,
      this._options.height,
    );
    this._segmentationMaskCanvas = document.createElement("canvas");
    this._segmentationMaskCanvas.width = this._options.width;
    this._segmentationMaskCanvas.height = this._options.height;
    this._segmentationMaskCtx = this._segmentationMaskCanvas.getContext("2d");

    this._outputCanvasElement.width = parseInt(width, 10);
    this._outputCanvasElement.height = parseInt(height, 10);
    this._outputCanvasCtx = this._outputCanvasElement.getContext("2d");
    this._inputVideoElement.width = parseInt(width, 10);
    this._inputVideoElement.height = parseInt(height, 10);
    this._inputVideoElement.autoplay = true;
    this._inputVideoElement.srcObject = this._stream;
    this._inputVideoElement.onloadeddata = () => {
      this._maskFrameTimerWorker.postMessage({
        id: SET_TIMEOUT,
        timeMs: 1000 / 30,
      });
    };

    return this._outputCanvasElement.captureStream(parseInt(frameRate, 10));
  }

  /**
   * Stops the capture and render loop.
   *
   * @returns {void}
   */
  stopEffect() {
    this._maskFrameTimerWorker.postMessage({
      id: CLEAR_TIMEOUT,
    });

    this._maskFrameTimerWorker.terminate();
  }
}

const models = {
  modelLandscape: "assets/scripts/selfie_segmentation_landscape.tflite",
};

let modelBuffer;
let tflite;
let wasmCheck;
let isWasmDisabled = false;

const segmentationDimensions = {
  modelLandscape: {
    height: 144,
    width: 256,
  },
};

export function timeout(milliseconds, promise) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error("408"));

      return;
    }, milliseconds);

    promise.then(resolve, reject);
  });
}
export async function createVirtualBackgroundEffect(virtualBackground) {
  if (
    !MediaStreamTrack.prototype.getSettings &&
    !MediaStreamTrack.prototype.getConstraints
  ) {
    throw new Error("JitsiStreamBackgroundEffect not supported!");
  }

  if (isWasmDisabled) {
    console.error("virtualBackground.backgroundEffectError WasmDisabled");
    return;
  }

  // Checks if WebAssembly feature is supported or enabled by/in the browser.
  // Conditional import of wasm-check package is done to prevent
  // the browser from crashing when the user opens the app.

  if (!tflite) {
    try {
      wasmCheck = require("wasm-check");
      const tfliteTimeout = 10000;

      if (wasmCheck?.feature?.simd) {
        tflite = await timeout(tfliteTimeout, createTFLiteSIMDModule());
      } else {
        tflite = await timeout(tfliteTimeout, createTFLiteModule());
      }
    } catch (err) {
      if (err?.message === "408") {
        console.error(
          "Failed to download tflite model! virtualBackground.backgroundEffectError",
        );
      } else {
        isWasmDisabled = true;
        console.error(
          "Looks like WebAssembly is disabled or not supported on this browser",
          err,
        );
      }

      return;
    }
  }

  if (!modelBuffer) {
    const modelResponse = await fetch(models.modelLandscape);

    if (!modelResponse.ok) {
      throw new Error("Failed to download tflite model!");
    }

    modelBuffer = await modelResponse.arrayBuffer();

    tflite.HEAPU8.set(
      new Uint8Array(modelBuffer),
      tflite._getModelBufferMemoryOffset(),
    );

    tflite._loadModel(modelBuffer.byteLength);
  }

  const options = {
    ...segmentationDimensions.modelLandscape,
    virtualBackground,
  };

  return new JitsiStreamBackgroundEffect(tflite, options);
}

export async function toggleBackgroundEffect(options, jitsiTrack, type) {
  console.log("options, jitsiTrack", type, options, jitsiTrack);
  if (jitsiTrack) {
    try {
      if (options.backgroundEffectEnabled) {
        await jitsiTrack.setEffect(
          await createVirtualBackgroundEffect(options),
        );
      } else {
        await jitsiTrack.setEffect(undefined);
      }
    } catch (error) {
      console.error("Error on apply background effect:", error);
    }
  }
}
