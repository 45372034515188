/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useMemo, useState } from "react";
import ReactTooltip from "react-tooltip";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SketchBoard from "./sketchBoard";
import { getTransport } from "../../transport";
import {
  localAudioTrackUnMute,
  localVideoTrackMute,
  localVideoTrackUnMute,
  HangupCall,
  init,
  startscreenshare,
  stopscreenshare,
  GetRoom,
  startrecording,
  stoprecording,
  getAudioTracks,
  getVideoTracks,
  getDesktopTracks,
  getLocalVideoTrack,
  changeTrackResolution,
} from "./index";
import {
  hangupOutBoundCall,
  hangupInboundCall,
  hangupOutBoundMonitorCall,
  _getAvatarStyle,
  getAvatarColor,
  showRecordingButton,
  requestCallParticipiantsVideos,
  setGalleryTracks,
  pinUserClass,
  getKeyId,
  requestPinParticipiantVideos,
  ShowActiveSpeakerOnFirstPage,
  muteMic,
  sendHangup,
} from "../CallCommon";
import {
  setDesktopScreenShareShow,
  setIsActivePenDraw,
  setisActiveSpeaker,
} from "./libSlice";
import {
  setAddPeoplePopUp,
  setIsOpenWhiteBoard,
  setRecordingConsentUserIds,
  setSharedFileData,
} from "../../redux/callSlice";
import store from "../../../store";
import AddPeople from "../../models/AddPeople";
import AlertModal from "../../models/AlertModal";
import DesktopScreenShareModal from "../../models/DesktopScreenShareModal";
import { RemoveUserFromCall } from "../../Helpers/CallCommon";
import {
  _updateCall,
  raiseHandNotification,
  _callDocUpdate,
  _updateCallParticipants,
  _sendEvent,
} from "../../../SocketIo";
import { RECORDING_ENABLE, SCREENDRAW_ENABLE } from "../../../constants";
import Video from "./video";
import Audio from "./audio";
import { globalStrings } from "../../util/translation/languages";
import CanvasUI from "../../customWhiteBoard/CanvasUI";
import profileDefault from "../../../assets/images/profile-default.png";
import pencilIcon from "../../../assets/images/pencil-icon.png";
import pencilIconWhite from "../../../assets/images/pencil-icon-white.png";
import pencilIconUndo from "../../../assets/images/pincil-icon-undo.png";
import UploadFile from "../../models/UploadFile";
import MediaControls from "./mediaControlers";
import Draggable from "react-draggable";
import {
  compareVersions,
  getAuthSession,
  getRandomColor,
  isElectron,
  isPlatform,
} from "../common";
import Pagination_custom from "../../pagination/pagination";
import { useRef } from "react";

let desktopSharingSources = null;
let onSourceChoose = null;
function setDesktopScreenShareSource(t, v) {
  desktopSharingSources = t;
  onSourceChoose = v;
  store.dispatch(setDesktopScreenShareShow(true));
}
let appVersion = "2.10.6";

const transport = getTransport();
const color = getRandomColor();
const VideoContainerHtml = (props) => {
  const [videoContainer, setVideoContainer] = useState("");
  const [audioContainer, setAudioContainer] = useState("");
  const [kickOutAlert, setKickOutAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [userId, setUserId] = useState(null);
  const [pinUserId, setPinUserId] = useState(null);
  const [userHandRaise, setUserHandRaise] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [closeStage, setCloseStage] = useState(false);
  const [screenWidth, setScreenWidth] = useState();
  const [screenHeight, setScreenHeight] = useState();
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 });
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  });

  console.log("pinUserId", pinUserId);

  const videoContainerVisible = useSelector(
    (state) => state.lib.videoContainerVisible,
  );
  const isActiveCamera = useSelector((state) => state.lib.isActiveCamera);
  const isActiveSpeaker = useSelector((state) => state.lib.isActiveSpeaker);
  const isActiveMic = useSelector((state) => state.lib.isActiveMic);
  const isActiveScreenSharing = useSelector(
    (state) => state.lib.isActiveScreenSharing,
  );

  const isActiveRecording = useSelector((state) => state.lib.isActiveRecording);
  const recordingLoader = useSelector((state) => state.lib.recordingLoader);
  const addPeoplePopUp = useSelector((state) => state.call.addPeoplePopUp);
  const allAttendeeList = useSelector((state) => state.call.allAttendeeList);

  const callDetail = useSelector((state) => state.call.callDetail);
  const isOpenWhiteBoard = useSelector((state) => state.call.isOpenWhiteBoard);
  const sharedFileData = useSelector((state) => state.call.sharedFileData);
  const electronAppVersion = useSelector(
    (state) => state.call.electronAppVersion,
  );

  const desktopScreenShareShow = useSelector(
    (state) => state.lib.desktopScreenShareShow,
  );

  const iframeLoading = useSelector((state) => state.lib.iframeLoading);
  const participants = useSelector((state) => state.lib.participants);
  const currrentPage = useSelector((state) => state.call.currrentPage);
  const galleryViewParticipants = useSelector(
    (state) => state.lib.galleryViewParticipants,
  );
  const participantsDesktopVideoTrack = useSelector(
    (state) => state.lib.desktopVideoTrack,
  );

  const currentActiveVideoTracks = useSelector(
    (state) => state.lib.currentActiveVideoTracks,
  );

  const localDesktopVideoTrack = useSelector(
    (state) => state.lib.localDesktopVideoTrack,
  );

  const activeSpeaker = useSelector((state) => state.lib.activeSpeaker);

  const isActivePenDraw = useSelector((state) => state.lib.isActivePenDraw);
  const localVideoTrack = useSelector((state) => state.lib.localVideoTrack);
  const sharedScreenType = useSelector((state) => state.lib.sharedScreenType);
  const selectedLanguage = useSelector((state) => state.chat.selectedLanguage);
  const authSessionUser = useSelector(
    (state) => state.authUserDetail.authSessionUser,
  );
  const { user_unique_key } = getAuthSession();
  const [sketchBoardIds, setSketchBoardIds] = useState(null);
  const sketchBoardPosition = useSelector(
    (state) => state.call.sketchBoardPosition,
  );

  const isLatestVersion = useMemo(() => {
    return electronAppVersion !== ""
      ? compareVersions(electronAppVersion, appVersion) !== -1
      : false;
  }, [electronAppVersion]);

  useEffect(() => {
    let drag_Width = (props?.width * window.innerWidth) / 100;
    const widthNumber = drag_Width / 4;
    setScreenWidth(widthNumber);
  }, [props?.width]);

  useEffect(() => {
    // console.log(
    //   "props?.height, window.innerHeight dragable",
    //   props?.height,
    //   window.innerHeight,
    // );
    let drag_height = (props?.height * window.innerHeight) / 100;
    const heightNumber = drag_height;

    setScreenHeight(heightNumber);
  }, [props?.height]);

  const calculateBounds = (screenWidth) => {
    let leftBound, rightBound;

    if (screenWidth >= 199 && screenWidth <= 218) {
      rightBound = Math.round(screenWidth) - 180;
      leftBound = -Math.round(screenWidth) + 180;
    }
    if (screenWidth >= 218 && screenWidth <= 240) {
      rightBound = Math.round(screenWidth) - 30;
      leftBound = -Math.round(screenWidth) + 30;
    } else if (screenWidth >= 240 && screenWidth <= 260) {
      rightBound = Math.round(screenWidth) - 10;
      leftBound = -Math.round(screenWidth) + 10;
    } else if (screenWidth >= 260 && screenWidth <= 280) {
      rightBound = Math.round(screenWidth) + 20;
      leftBound = -Math.round(screenWidth) - 20;
    } else if (screenWidth >= 280 && screenWidth <= 300) {
      rightBound = Math.round(screenWidth) + 40;
      leftBound = -Math.round(screenWidth) - 40;
    } else if (screenWidth >= 300 && screenWidth <= 320) {
      rightBound = Math.round(screenWidth) + 60;
      leftBound = -Math.round(screenWidth) - 60;
    } else if (screenWidth >= 320 && screenWidth <= 340) {
      rightBound = Math.round(screenWidth) + 80;
      leftBound = -Math.round(screenWidth) - 80;
    } else if (screenWidth >= 340 && screenWidth <= 360) {
      rightBound = Math.round(screenWidth) + 100;
      leftBound = -Math.round(screenWidth) - 100;
    } else if (screenWidth >= 360 && screenWidth <= 380) {
      rightBound = Math.round(screenWidth) + 120;
      leftBound = -Math.round(screenWidth) - 120;
    } else if (screenWidth >= 380 && screenWidth <= 400) {
      rightBound = Math.round(screenWidth) + 140;
      leftBound = -Math.round(screenWidth) - 140;
    } else if (screenWidth >= 400 && screenWidth <= 420) {
      rightBound = Math.round(screenWidth) + 160;
      leftBound = -Math.round(screenWidth) - 160;
    } else if (screenWidth >= 420 && screenWidth <= 440) {
      rightBound = Math.round(screenWidth) + 180;
      leftBound = -Math.round(screenWidth) - 180;
    } else if (screenWidth >= 440 && screenWidth <= 460) {
      rightBound = Math.round(screenWidth) + 200;
      leftBound = -Math.round(screenWidth) - 200;
    } else if (screenWidth >= 460 && screenWidth <= 480) {
      rightBound = Math.round(screenWidth) + 220;
      leftBound = -Math.round(screenWidth) - 220;
    } else {
      // Default bounds if none of the above conditions match
      rightBound = Math.round(screenWidth);
      leftBound = -Math.round(screenWidth);
    }

    return { leftBound, rightBound };
  };

  const handleDrag = (e, data) => {
    setCurrentPos({ x: data.x, y: data.y });

    const { leftBound, rightBound } = calculateBounds(screenWidth);

    setBounds({
      left: leftBound,
      top: -Math.round(screenHeight) + 100,
      right: rightBound,
      bottom: 0,
    });
  };

  useEffect(() => {
    if (props?.isDefaultScreen) {
      setCurrentPos({ x: 0, y: 0 });
    }
  }, [props?.isDefaultScreen]);

  useEffect(() => {
    if (isActiveScreenSharing) {
      transport.sendEvent({
        name: "toolbar-icons-update",
        type: "audio",
        value: isActiveMic,
      });
    }
  }, [isActiveMic, isActiveScreenSharing]);

  useEffect(() => {
    if (isActiveScreenSharing) {
      transport.sendEvent({
        name: "toolbar-icons-update",
        type: "video",
        value: isActiveCamera,
      });
    }
  }, [isActiveCamera, isActiveScreenSharing]);

  useEffect(() => {
    if (!isActiveScreenSharing && isActivePenDraw) {
      transport.sendEvent({
        name: "toolbar-icons-update",
        type: "screenshare",
        value: isActiveScreenSharing,
      });
      store.dispatch(setIsActivePenDraw(false));
    }
  }, [isActiveScreenSharing, isActivePenDraw]);

  useEffect(() => {
    transport.on("event", (event) => {
      if (event.name === "send-toolbar-update-icon-request") {
        const iconData = event?.data[0]?.iconData ?? {};
        if (iconData?.type === "audio") {
          const isActiveMic = store.getState().lib.isActiveMic;
          if (iconData?.value === false && isActiveMic) {
            muteMic();
          } else if (iconData?.value === true && !isActiveMic) {
            localAudioTrackUnMute();
          }
          // eslint-disable-next-line no-dupe-else-if
        } else if (iconData?.type === "video") {
          const isActiveCamera = store.getState().lib.isActiveCamera;
          if (iconData?.value === false && isActiveCamera) {
            localVideoTrackMute();
          } else if (iconData?.value === true && !isActiveCamera) {
            localVideoTrackUnMute();
          }
        } else if (iconData?.type === "screenshare") {
          const isActiveScreenSharingValue =
            store.getState().lib.isActiveScreenSharing;

          if (iconData?.value === false && isActiveScreenSharingValue) {
            stopscreenshare();
          }
        } else if (iconData?.type === "penDraw") {
          const isActivePenDraw = store.getState().lib.isActivePenDraw;
          if (iconData?.value === false && isActivePenDraw) {
            store.dispatch(setIsActivePenDraw(false));
          }
        }
      }
    });
  }, []);

  useEffect(() => {
    ShowActiveSpeakerOnFirstPage();
  }, [activeSpeaker]);

  useEffect(() => {
    if (
      pinUserId !== "localscreen" &&
      pinUserId !== null &&
      pinUserId.includes("screen")
    ) {
      if (participantsDesktopVideoTrack.length > 0) {
        const isDesktopUserExist = participantsDesktopVideoTrack.some(
          (item) => {
            const tempPinUserId = `${item?.participantId}screen`;
            return tempPinUserId === pinUserId;
          },
        );
        if (!isDesktopUserExist) {
          setPinUserId(null);
        }
      } else {
        setPinUserId(null);
      }
    }
  }, [participantsDesktopVideoTrack]);

  useEffect(() => {
    if (
      pinUserId === "localscreen" &&
      (localDesktopVideoTrack === null ||
        localDesktopVideoTrack?.muted === true)
    ) {
      setPinUserId(null);
    }
  }, [localDesktopVideoTrack]);

  useEffect(() => {
    if (
      localDesktopVideoTrack !== null ||
      localDesktopVideoTrack?.muted === false
    ) {
      const id = localDesktopVideoTrack.jitsiTrack.getSourceName();
      const userID = authSessionUser?.user_id;
      if (
        sketchBoardPosition[id] &&
        sketchBoardPosition[id]?.user_id !== userID
      ) {
        const positions = sketchBoardPosition[id]?.position;
        transport.sendEvent({
          name: "sketch-Board-clicked",
          type: "clientDraw",
          color: sketchBoardPosition[id].color,
          position: positions,
          clearBoard: sketchBoardPosition[id]?.clearBoard,
          sharedScreenType,
        });
      }
    }
  }, [sketchBoardPosition]);
  // useEffect(() => {
  //   if (props.callDetails.type === "schedule") {
  //     if (props.user_unique_key === props.callDetails.senderID) {
  //       store.dispatch(setvideoContainerVisible("inherit"));
  //       store.dispatch(setiframeLoading(false));
  //     }
  //   }
  // }, [props.callDetails]);

  const kickOutHandler = (user_id) => {
    const userName =
      allAttendeeList.find((filterItem) => filterItem.user_id === user_id)
        ?.name ?? "";
    setAlertMsg(`Are you sure you want to Kick Out ${userName} from Call ?`);
    setKickOutAlert(true);
    setUserId(user_id);
  };

  useEffect(() => {
    if (pinUserId !== null) {
      requestPinParticipiantVideos(pinUserId);
    } else {
      requestCallParticipiantsVideos();
    }
  }, [
    currrentPage,
    callDetail?.participantID,
    participantsDesktopVideoTrack,
    localDesktopVideoTrack,
    pinUserId,
  ]);

  useEffect(() => {
    setGalleryTracks(pinUserId);
  }, [
    currentActiveVideoTracks,
    localVideoTrack,
    localDesktopVideoTrack,
    pinUserId,
  ]);

  useEffect(() => {
    setVideoContainer(
      getVideoContainer(
        props,
        kickOutHandler,
        pinUserId,
        setPinUserId,
        raiseHand,
        localDesktopVideoTrack,
        activeSpeaker,
        authSessionUser,
        allAttendeeList,
        sketchBoardIds,
        setSketchBoardIds,
        closeStage,
        localVideoTrack,
        isLatestVersion,
        galleryViewParticipants,
        currentActiveVideoTracks,
      ),
    );
    setAudioContainer(getAudioContainer(isActiveSpeaker));
  }, [
    props.callDetails,
    localDesktopVideoTrack,
    activeSpeaker,
    sketchBoardIds,
    localVideoTrack,
    galleryViewParticipants,
    currentActiveVideoTracks,
    isActiveSpeaker,
  ]);

  const addPeople = (e) => {
    e.preventDefault();
    store.dispatch(setAddPeoplePopUp(!addPeoplePopUp));
  };

  const toggleWhiteBoard = async (e) => {
    e.preventDefault();
    const updatedCallObject = Object.assign({}, callDetail, {
      whiteBoard: callDetail && callDetail?.whiteBoard === true ? false : true,
    });
    await _callDocUpdate(updatedCallObject);
  };

  const getUserIdFromParticipantId = (participantId) => {
    const room = GetRoom();
    let userId = "";
    const participantsDetail = room != null ? room.getParticipants() : {};
    const displayName =
      participantsDetail.length > 0
        ? participantsDetail?.find((findItem) => findItem._id === participantId)
            ?._displayName ?? null
        : null;
    let displayNameArrayData = null;
    if (displayName !== null) {
      displayNameArrayData = displayName?.split("_");
    }
    if (displayNameArrayData !== null) {
      userId = displayNameArrayData[1];
    }
    return userId;
  };

  useEffect(async () => {
    if (pinUserId !== null) {
      const getConvertedUserId = await getUserIdFromParticipantId(pinUserId);
      const { participantID } = props.callDetails;
      const pinUserCallDetail =
        participantID != undefined
          ? participantID.length > 0
            ? participantID.find(
                (findItem) => findItem.id === getConvertedUserId,
              )
            : {}
          : {};
      if (
        pinUserCallDetail?.status === "ended" ||
        pinUserCallDetail?.status === "decline"
      ) {
        setPinUserId(null);
      }
    }
    const { type, participantID, senderID } = props.callDetails;
    if (type === "private" && participantID.length === 0) {
      if (userHandRaise !== props.callDetails.hand_raise) {
        setUserHandRaise(props.callDetails.hand_raise);
      }
    } else {
      const handRaiseFlag =
        participantID?.find((findItem) => findItem.id === props.user_unique_key)
          ?.hand_raise ?? false;
      if (userHandRaise !== handRaiseFlag) {
        setUserHandRaise(handRaiseFlag);
      }
    }
  }, [props.callDetails]);

  const alertRemove = async () => {
    if (pinUserId !== null) {
      const getConvertedUserId = await getUserIdFromParticipantId(pinUserId);
      if (getConvertedUserId === userId) {
        setPinUserId(null);
      }
    }

    RemoveUserFromCall(userId, props.callDetails, allAttendeeList);
    setUserId(null);
    setKickOutAlert(false);
  };

  useEffect(() => {
    if (isActiveRecording) {
      if (props.user_unique_key !== props?.callDetails?.recorderUserId) {
        let recorderUserId = [];
        if (
          props?.callDetails?.type === "group" ||
          (props?.callDetails?.type === "private" &&
            props?.callDetails?.user_added === true)
        ) {
          // eslint-disable-next-line no-unsafe-optional-chaining
          const { participantID } = props?.callDetails;
          participantID.forEach((item) => {
            if (item?.status === "accepted") {
              recorderUserId.push(item.id);
            }
          });
        } else {
          // eslint-disable-next-line no-unsafe-optional-chaining
          const { receiverID } = props?.callDetails;
          recorderUserId.push(receiverID);
        }

        store.dispatch(setRecordingConsentUserIds(recorderUserId));
      }
    } else {
      store.dispatch(setRecordingConsentUserIds([]));
    }
  }, [isActiveRecording]);

  useEffect(() => {
    store.dispatch(setSharedFileData(callDetail?.sharedFile));
  }, [callDetail?.sharedFile]);

  useEffect(() => {
    if (Object.keys(sharedFileData).length > 0) {
      const { fileType, fileUrl, play } = sharedFileData;
      if (fileType === "audio") {
        let audio = document.getElementById("audioPlayer");
        let audioSource = document.createElement("source");
        audioSource.setAttribute("src", fileUrl);
        audio.appendChild(audioSource);
        if (play) {
          audio.play();
        } else {
          audio.pause();
        }

        audio.onended = (e) => {
          store.dispatch(setSharedFileData({}));
        };
      } else {
        let video = document.getElementById("videoPlayer");
        let videoSource = document.createElement("source");
        videoSource.setAttribute("src", fileUrl);
        video.appendChild(videoSource);
        if (play) {
          video.play();
        } else {
          video.pause();
        }
        video.onended = (e) => {
          store.dispatch(setSharedFileData({}));
        };
      }
    }
  }, [sharedFileData]);

  const toggleShareFile = (e) => {
    e.preventDefault();
    if (Object.keys(sharedFileData).length > 0) {
      const updatedCallDetail = { ...callDetail };
      updatedCallDetail["sharedFile"] = {};
      _callDocUpdate(updatedCallDetail);
    } else {
      setOpenUploadModal(true);
    }
  };
  const manageMediaControls = (e) => {
    e.preventDefault();
    const updatedCallDetail = { ...callDetail };
    const updatedSharedFile = { ...updatedCallDetail["sharedFile"] };
    updatedSharedFile["play"] = !callDetail["sharedFile"].play;
    updatedCallDetail["sharedFile"] = updatedSharedFile;
    _callDocUpdate(updatedCallDetail);
  };
  const toggleSketchBoard = async (event) => {
    event.preventDefault();
    if (isActivePenDraw) {
      // setCloseStage(true);
      transport.sendEvent({
        name: "toolbar-icons-update",
        type: "screenshare",
        value: false,
      });
      store.dispatch(setIsActivePenDraw(false));
    } else {
      const myUserId = authSessionUser.user_id;
      const userData = allAttendeeList.find(
        (filterItem) => filterItem.user_id === myUserId,
      );
      store.dispatch(setIsActivePenDraw(true));
      if (userData?.screenShareSource === "screen") {
        const platform = isPlatform();
        transport.sendEvent({
          name: "sketch-Board-clicked",
          type: "userDrawFullScreen",
          edit: true,
          color: color,
          platform,
        });
        // setCloseStage(false);
      } else {
        transport.sendEvent({
          name: "sketch-Board-clicked",
          type: "userDrawApp",
          edit: true,
          color: color,
        });
      }
    }
  };
  useEffect(
    () => () => {
      transport.sendEvent({
        name: "sketch-Board-clicked",
        type: "userDrawFullScreen",
        edit: false,
        color: color,
      });
      transport.sendEvent({
        name: "sketch-Board-clicked",
        type: "userDrawApp",
        edit: false,
        color: color,
      });
      transport.sendEvent({
        name: "sketch-Board-clicked",
        type: "clientDrawResetBoard",
      });
    },

    [],
  );

  const showControls =
    user_unique_key === sharedFileData?.user_id ? true : false;
  const showShareFileIcon =
    Object.keys(sharedFileData).length > 0
      ? user_unique_key === sharedFileData?.user_id
        ? true
        : false
      : true;

  const showRecordingButtonFlag = useMemo(() => {
    return showRecordingButton(props);
  }, [props?.callDetail]);

  return (
    <>
      <div
        className={`video__wrapper d-flex justify-content-center ${
          isOpenWhiteBoard === true ? "white-board-style" : ""
        }`}
      >
        <div
          className="video__container d-flex justify-content-center flex-wrap position-relative"
          style={{ visibility: videoContainerVisible }}
        >
          {Object.keys(sharedFileData).length > 0 && (
            <>
              {sharedFileData?.fileType === "audio" ? (
                <>
                  {showControls && (
                    <a
                      href={void 0}
                      onClick={manageMediaControls}
                      className="icon__tag bg-white d-flex align-items-center justify-content-center icon_container index-3 mr-2 position-absolute icon-pause"
                    >
                      {sharedFileData?.play ? (
                        <svg width="12" viewBox="8 7 8 10">
                          <path fill="#b8c0c9" d="M8 7h3v10H8zm5 0h3v10h-3z" />
                        </svg>
                      ) : (
                        <svg width="12" viewBox="7 6 10 12">
                          <path fill="#b8c0c9" d="M7 6v12l10-6z" />
                        </svg>
                      )}
                    </a>
                  )}

                  <audio id="audioPlayer" width="100%" height="100%"></audio>
                  <div className="d-flex flex-column align-items-center justify-content-center audio-file text-sm position-absolute audio-img-container text-white">
                    <img
                      src="assets/images/music-file.png"
                      className="audio-img"
                    />
                    Audio is playing...
                  </div>
                </>
              ) : (
                <>
                  {showControls && (
                    <a
                      href={void 0}
                      onClick={manageMediaControls}
                      className="icon__tag bg-white d-flex align-items-center justify-content-center icon_container index-3 mr-2 position-absolute icon-pause"
                    >
                      {sharedFileData?.play ? (
                        <svg width=".9em" viewBox="8 7 8 10">
                          <path fill="#b8c0c9" d="M8 7h3v10H8zm5 0h3v10h-3z" />
                        </svg>
                      ) : (
                        <svg width=".9em" viewBox="7 6 10 12">
                          <path fill="#b8c0c9" d="M7 6v12l10-6z" />
                        </svg>
                      )}
                    </a>
                  )}

                  <video id="videoPlayer" width="100%" height="100%"></video>
                </>
              )}
            </>
          )}
          {Object.keys(sharedFileData).length > 0 &&
          sharedFileData?.fileType === "video"
            ? ""
            : videoContainer}
          {(props?.callDetails?.monitor_status === undefined ||
            props?.callDetails?.monitor_status === false) &&
          props.user_unique_key === props?.callDetails?.senderID ? (
            <div className="icon__container position-absolute top-left d-flex justify-content-between index-1 w-100 p-3">
              <div className="d-flex">
                <div className="position-relative d-flex">
                  <Link
                    to="#"
                    className="icon__tag icon-add bg-white d-flex align-items-center justify-content-center icon_container index-3 mr-2"
                    onClick={addPeople}
                  >
                    <svg viewBox="0 0 24 24" width={20} height={20}>
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        fill="#b8c0c9"
                        d="M14 14.252v2.09A6 6 0 0 0 6 22l-2-.001a8 8 0 0 1 10-7.748zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm6 6v-3h2v3h3v2h-3v3h-2v-3h-3v-2h3z"
                      />
                    </svg>
                  </Link>
                  {/* <Link
                  to="#"
                  className="icon__tag bg-white d-flex align-items-center justify-content-center icon_container index-3"
                  onClick={openWhiteBoard}
                >
                  <svg viewBox="2 3 20 18.6" width={20} height={20}>
                    <path
                      fill="#b8c0c9"
                      d="M20 3H4c-1.103 0-2 .897-2 2v11c0 1.103.897 2 2 2h4l-1.8 2.4 1.6 1.2 2.7-3.6h3l2.7 3.6 1.6-1.2L16 18h4c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM4 16V5h16l.001 11H4z"
                    ></path>
                    <path fill="#b8c0c9" d="M6 12h4v2H6z"></path>
                  </svg>
                </Link> */}
                  {addPeoplePopUp && (
                    <AddPeople
                      show={addPeoplePopUp}
                      handleClose={() =>
                        store.dispatch(setAddPeoplePopUp(false))
                      }
                      allAttendeeList={allAttendeeList}
                      callDetails={props.callDetails}
                      selectedLanguage={selectedLanguage}
                    />
                  )}
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <Draggable onDrag={handleDrag} bounds={bounds} position={currentPos}>
          <div className="video__toolbar">
            {props?.callDetails?.monitor_status === undefined ||
              (props?.callDetails?.monitor_status === false && (
                <>
                  {userHandRaise ? (
                    <div className="toolbar__tag">
                      <a
                        href=""
                        className="d-flex align-items-center"
                        onClick={(e) =>
                          raiseHand(e, props, props.user_unique_key, false)
                        }
                      >
                        <svg
                          fill="none"
                          viewBox="7.65 4.5 42.85 51"
                          width="25px"
                          height="25px"
                        >
                          <path
                            d="M35 8.75V27.5V11.25C35 9.17893 36.679 7.5 38.75 7.5C40.821 7.5 42.5 9.17893 42.5 11.25V27.5V18.75C42.5 16.6789 44.179 15 46.25 15C48.321 15 50 16.6789 50 18.75V40C50 48.2842 43.2842 55 35 55H32.1817C28.3707 55 24.7028 53.5495 21.9226 50.943L9.52612 39.3213C7.73412 37.6413 7.6885 34.8115 9.4254 33.0745C11.1235 31.3765 13.8766 31.3765 15.5746 33.0745L20 37.5V16.25C20 14.1789 21.6789 12.5 23.75 12.5C25.821 12.5 27.5 14.1789 27.5 16.25V27.5V8.75C27.5 6.67893 29.179 5 31.25 5C33.321 5 35 6.67893 35 8.75Z"
                            fill="#FFFFFF"
                            stroke="grey"
                            strokeWidth="1"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </a>
                    </div>
                  ) : (
                    <div
                      className="tooltip position-relative toolbar__tag"
                      data-tip
                      data-for="handrise"
                    >
                      <a
                        href=""
                        className="d-flex align-items-center"
                        onClick={(e) => {
                          raiseHandNotification(),
                            raiseHand(e, props, props.user_unique_key, true);
                        }}
                      >
                        <svg
                          fill="none"
                          width="25px"
                          height="25px"
                          viewBox="5.65 2.5 46.85 55"
                        >
                          <path
                            d="M35 8.75V27.5V11.25C35 9.17893 36.679 7.5 38.75 7.5C40.821 7.5 42.5 9.17893 42.5 11.25V27.5V18.75C42.5 16.6789 44.179 15 46.25 15C48.321 15 50 16.6789 50 18.75V40C50 48.2842 43.2842 55 35 55H32.1817C28.3707 55 24.7028 53.5495 21.9226 50.943L9.52612 39.3213C7.73412 37.6413 7.6885 34.8115 9.4254 33.0745C11.1235 31.3765 13.8766 31.3765 15.5746 33.0745L20 37.5V16.25C20 14.1789 21.6789 12.5 23.75 12.5C25.821 12.5 27.5 14.1789 27.5 16.25V27.5V8.75C27.5 6.67893 29.179 5 31.25 5C33.321 5 35 6.67893 35 8.75Z"
                            stroke="#FFFFFF"
                            strokeWidth="5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                        <ReactTooltip
                          id={"handrise"}
                          place="top"
                          effect="solid"
                        >
                          {globalStrings[selectedLanguage]?.$raiseHand}
                        </ReactTooltip>
                      </a>
                    </div>
                  )}
                  {/* </>
                 )} */}
                  {isActiveMic ? (
                    <div
                      className="tooltip position-relative toolbar__tag"
                      data-tip
                      data-for="registerTip"
                    >
                      <a
                        href=""
                        className="icon-audio d-flex align-items-center"
                        onClick={toggleAudio}
                      >
                        <svg
                          width="25px"
                          height="25px"
                          viewBox="4 1.98 16 20.02"
                        >
                          <path
                            fill="#ffffff"
                            d="M16 12V6c0-2.217-1.785-4.021-3.979-4.021a.933.933 0 0 0-.209.025A4.006 4.006 0 0 0 8 6v6c0 2.206 1.794 4 4 4s4-1.794 4-4zm-6 0V6c0-1.103.897-2 2-2a.89.89 0 0 0 .163-.015C13.188 4.06 14 4.935 14 6v6c0 1.103-.897 2-2 2s-2-.897-2-2z"
                          ></path>
                          <path
                            fill="#ffffff"
                            d="M6 12H4c0 4.072 3.061 7.436 7 7.931V22h2v-2.069c3.939-.495 7-3.858 7-7.931h-2c0 3.309-2.691 6-6 6s-6-2.691-6-6z"
                          ></path>
                        </svg>

                        <ReactTooltip
                          id={"registerTip"}
                          place="top"
                          effect="solid"
                        >
                          {globalStrings[selectedLanguage]?.$mic}
                        </ReactTooltip>
                      </a>
                    </div>
                  ) : (
                    <div
                      className="tooltip position-relative toolbar__tag"
                      data-tip
                      data-for="registerTipUnMute"
                    >
                      <a
                        href=""
                        className="icon-audio d-flex align-items-center"
                        onClick={toggleAudio}
                      >
                        <svg
                          width="25px"
                          height="25px"
                          viewBox="2.29 2 19.41 20.02"
                        >
                          <path
                            fill="#ffffff"
                            d="m21.707 20.293-3.388-3.388A7.942 7.942 0 0 0 20 12.021h-2a5.95 5.95 0 0 1-1.109 3.456l-1.452-1.452c.348-.591.561-1.27.561-2.004v-6C16 3.804 14.215 2 12.021 2c-.07 0-.14.009-.209.025A4.005 4.005 0 0 0 8 6.021v.565L3.707 2.293 2.293 3.707l18 18 1.414-1.414zM10 6.021c0-1.103.897-2 2-2a.918.918 0 0 0 .164-.015C13.188 4.08 14 4.956 14 6.021v6c0 .172-.029.335-.071.494L10 8.586V6.021zm-4 6H4c0 4.072 3.06 7.436 7 7.931v2.069h2v-2.07a7.993 7.993 0 0 0 2.218-.611l-1.558-1.558a5.979 5.979 0 0 1-1.66.239c-3.309 0-6-2.692-6-6z"
                          ></path>
                          <path
                            fill="#ffffff"
                            d="M8.011 12.132a3.993 3.993 0 0 0 3.877 3.877l-3.877-3.877z"
                          ></path>
                        </svg>
                        <ReactTooltip
                          id={"registerTipUnMute"}
                          place="top"
                          effect="solid"
                        >
                          {globalStrings[selectedLanguage]?.$mic}
                        </ReactTooltip>
                      </a>
                    </div>
                  )}
                  {isActiveCamera ? (
                    <div
                      className="tooltip position-relative toolbar__tag"
                      data-tip
                      data-for="registerTipCamra"
                    >
                      <a
                        href=""
                        className="icon-video d-flex align-items-center"
                        onClick={toggleVideo}
                      >
                        <svg width="26px" height="26px" viewBox="2 5 20 14">
                          <path
                            fill="#ffffff"
                            d="M18 7c0-1.103-.897-2-2-2H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-3.333L22 17V7l-4 3.333V7zm-1.998 10H4V7h12l.001 4.999L16 12l.001.001.001 4.999z"
                          ></path>
                        </svg>
                      </a>
                      <ReactTooltip
                        id={"registerTipCamra"}
                        place="top"
                        effect="solid"
                      >
                        {globalStrings[selectedLanguage]?.$camera}
                      </ReactTooltip>
                    </div>
                  ) : (
                    <div
                      className="tooltip position-relative toolbar__tag"
                      data-tip
                      data-for="registerTipCamraOff"
                    >
                      <a
                        href=""
                        className="icon-video d-flex align-items-center"
                        onClick={toggleVideo}
                      >
                        <svg
                          width="26px"
                          height="26px"
                          viewBox="2 2.29 20 19.41"
                        >
                          <path
                            fill="#ffffff"
                            d="M18 7c0-1.103-.897-2-2-2H6.414L3.707 2.293 2.293 3.707l18 18 1.414-1.414L18 16.586v-2.919L22 17V7l-4 3.333V7zm-2 7.586L8.414 7H16v7.586zM4 19h10.879l-2-2H4V8.121L2.145 6.265A1.977 1.977 0 0 0 2 7v10c0 1.103.897 2 2 2z"
                          ></path>
                        </svg>
                      </a>
                      <ReactTooltip
                        id={"registerTipCamraOff"}
                        place="top"
                        effect="solid"
                      >
                        {globalStrings[selectedLanguage]?.$camera}
                      </ReactTooltip>
                    </div>
                  )}

                  {isActiveSpeaker ? (
                    <div
                      className="tooltip position-relative toolbar__tag"
                      data-tip
                      data-for="registerTip"
                    >
                      <a
                        href=""
                        className="icon-audio d-flex align-items-center"
                        onClick={toggleSpeaker}
                      >
                        <svg width="25px" height="25px" viewBox="0 0 24 24">
                          <path
                            fill="#ffffff"
                            d="M16 21c3.527-1.547 5.999-4.909 5.999-9S19.527 4.547 16 3v2c2.387 1.386 3.999 4.047 3.999 7S18.387 17.614 16 19v2z"
                          ></path>
                          <path
                            fill="#ffffff"
                            d="M16 7v10c1.225-1.1 2-3.229 2-5s-.775-3.9-2-5zM4 17h2.697l5.748 3.832a1.004 1.004 0 0 0 1.027.05A1 1 0 0 0 14 20V4a1 1 0 0 0-1.554-.832L6.697 7H4c-1.103 0-2 .897-2 2v6c0 1.103.897 2 2 2zm0-8h3c.033 0 .061-.016.093-.019a1.027 1.027 0 0 0 .38-.116c.026-.015.057-.017.082-.033L12 5.868v12.264l-4.445-2.964c-.025-.017-.056-.02-.082-.033a.986.986 0 0 0-.382-.116C7.059 15.016 7.032 15 7 15H4V9z"
                          ></path>
                        </svg>

                        <ReactTooltip
                          id={"registerTip"}
                          place="top"
                          effect="solid"
                        >
                          {globalStrings[selectedLanguage]?.$speaker}
                        </ReactTooltip>
                      </a>
                    </div>
                  ) : (
                    <div
                      className="tooltip position-relative toolbar__tag"
                      data-tip
                      data-for="registerTipUnMute"
                    >
                      <a
                        href=""
                        className="icon-audio d-flex align-items-center"
                        onClick={toggleSpeaker}
                      >
                        <svg width="26px" height="26px" viewBox="0 0 24 24">
                          <path
                            fill="#ffffff"
                            d="m21.707 20.293-2.023-2.023A9.566 9.566 0 0 0 21.999 12c0-4.091-2.472-7.453-5.999-9v2c2.387 1.386 3.999 4.047 3.999 7a8.113 8.113 0 0 1-1.672 4.913l-1.285-1.285C17.644 14.536 18 13.19 18 12c0-1.771-.775-3.9-2-5v7.586l-2-2V4a1 1 0 0 0-1.554-.832L7.727 6.313l-4.02-4.02-1.414 1.414 18 18 1.414-1.414zM12 5.868v4.718L9.169 7.755 12 5.868zM4 17h2.697l5.748 3.832a1.004 1.004 0 0 0 1.027.05A1 1 0 0 0 14 20v-1.879l-2-2v2.011l-4.445-2.964c-.025-.017-.056-.02-.082-.033a.986.986 0 0 0-.382-.116C7.059 15.016 7.032 15 7 15H4V9h.879L3.102 7.223A1.995 1.995 0 0 0 2 9v6c0 1.103.897 2 2 2z"
                          ></path>
                        </svg>
                        <ReactTooltip
                          id={"registerTipUnMute"}
                          place="top"
                          effect="solid"
                        >
                          {globalStrings[selectedLanguage]?.$speaker}
                        </ReactTooltip>
                      </a>
                    </div>
                  )}

                  {showShareFileIcon && (
                    <div
                      className={`tooltip position-relative toolbar__tag ${
                        Object.keys(sharedFileData).length > 0 ? "selected" : ""
                      }`}
                      data-tip
                      data-for="sharedFile"
                    >
                      <a
                        href=""
                        className="icon-video d-flex align-items-center"
                        onClick={toggleShareFile}
                      >
                        <svg width="26px" viewBox="2 4 20 16">
                          <path
                            fill="#ffffff"
                            d="M11 15h2V9h3l-4-5-4 5h3z"
                          ></path>
                          <path
                            fill="#ffffff"
                            d="M20 18H4v-7H2v7c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2v-7h-2v7z"
                          ></path>
                        </svg>
                      </a>
                      <ReactTooltip
                        id={"sharedFile"}
                        place="top"
                        effect="solid"
                      >
                        {Object.keys(sharedFileData).length > 0
                          ? "Stop Sharing"
                          : "Share Audio or Video"}
                      </ReactTooltip>
                    </div>
                  )}
                  {!iframeLoading ? (
                    <>
                      {showRecordingButtonFlag ? (
                        recordingLoader ? (
                          <div className="toolbar__tag">
                            <a href="#" className="d-flex align-items-center">
                              <svg
                                version="1.1"
                                id="L9"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                viewBox="0 0 100 100"
                                width="40px"
                                height="40px"
                              >
                                <path
                                  width="100%"
                                  height="100%"
                                  fill="#fff"
                                  d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                                >
                                  <animateTransform
                                    attributeName="transform"
                                    attributeType="XML"
                                    type="rotate"
                                    dur="1s"
                                    from="0 50 50"
                                    to="360 50 50"
                                    repeatCount="indefinite"
                                  />
                                </path>
                                <circle
                                  cx="50"
                                  cy="50"
                                  r="10"
                                  stroke="white"
                                  strokeWidth="3"
                                  fill="white"
                                />
                              </svg>
                            </a>
                          </div>
                        ) : (
                          <div
                            data-tip
                            data-for="recording"
                            className={`tooltip position-relative toolbar__tag ${
                              isActiveRecording ? "selected" : ""
                            }`}
                          >
                            <a
                              href="#"
                              className="d-flex align-items-center icon-record"
                              onClick={(e) => toggleRecording(e, props)}
                            >
                              <svg
                                width="22px"
                                height="22px"
                                viewBox="5 5 14 14"
                              >
                                <path
                                  fill="#ffffff"
                                  d="M12 5c-3.859 0-7 3.141-7 7s3.141 7 7 7 7-3.141 7-7-3.141-7-7-7zm0 12c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z"
                                ></path>
                                <path
                                  fill="#ffffff"
                                  d="M12 9c-1.627 0-3 1.373-3 3s1.373 3 3 3 3-1.373 3-3-1.373-3-3-3z"
                                ></path>
                              </svg>
                              <ReactTooltip
                                id={"recording"}
                                place="top"
                                effect="solid"
                              >
                                {"Recording"}
                                {/* {"Recording service is under maintenance and expect to have it resolved shortly."} */}
                              </ReactTooltip>
                            </a>
                          </div>
                        )
                      ) : null}
                      <div
                        data-tip
                        data-for="ScreenShere"
                        className={`tooltip position-relative toolbar__tag ${
                          isActiveScreenSharing ? "selected" : ""
                        }`}
                      >
                        <a
                          href=""
                          className="d-flex align-items-center icon-share"
                          onClick={toggleScreenShare}
                        >
                          <div className="pos-relative d-flex align-items-center justify-content-center ">
                            <svg width="27px" height="27px" viewBox="2 3 20 17">
                              <path
                                fill="#ffffff"
                                d="M20 17.722c.595-.347 1-.985 1-1.722V5c0-1.103-.897-2-2-2H5c-1.103 0-2 .897-2 2v11c0 .736.405 1.375 1 1.722V18H2v2h20v-2h-2v-.278zM5 16V5h14l.002 11H5z"
                              ></path>
                            </svg>
                            <svg
                              className="flipY-180 mt-share"
                              width="9px"
                              height="9px"
                              viewBox="2.52 2.59 19.48 15.55"
                            >
                              <path
                                fill="#ffffff"
                                d="M11 6.914V2.586L6.293 7.293l-3.774 3.774 3.841 3.201L11 18.135V13.9c8.146-.614 11 4.1 11 4.1 0-2.937-.242-5.985-2.551-8.293C16.765 7.022 12.878 6.832 11 6.914z"
                              ></path>
                            </svg>

                            <ReactTooltip
                              id={"ScreenShere"}
                              place="top"
                              effect="solid"
                            >
                              {"Screen Share"}
                            </ReactTooltip>
                          </div>
                        </a>
                      </div>
                      <>
                        {SCREENDRAW_ENABLE &&
                          isLatestVersion &&
                          isActiveScreenSharing &&
                          isElectron &&
                          sharedScreenType === "screen" && (
                            <>
                              {isActivePenDraw ? (
                                <div
                                  data-tip
                                  data-for="SketchBoardOpen"
                                  className="tooltip position-relative toolbar__tag"
                                >
                                  <a
                                    href=""
                                    className="d-flex align-items-center icon-share"
                                    onClick={toggleSketchBoard}
                                  >
                                    <div className="pos-relative d-flex align-items-center justify-content-center ">
                                      <svg width="26px" viewBox="0 0 24 24">
                                        <path
                                          fill="#ffffff"
                                          d="M8.707 19.707 18 10.414 13.586 6l-9.293 9.293a1.003 1.003 0 0 0-.263.464L3 21l5.242-1.03c.176-.044.337-.135.465-.263zM21 7.414a2 2 0 0 0 0-2.828L19.414 3a2 2 0 0 0-2.828 0L15 4.586 19.414 9 21 7.414z"
                                        ></path>
                                      </svg>

                                      <ReactTooltip
                                        id={"SketchBoardOpen"}
                                        place="top"
                                        effect="solid"
                                      >
                                        {"Pen Draw"}
                                      </ReactTooltip>
                                    </div>
                                  </a>
                                </div>
                              ) : (
                                <div
                                  data-tip
                                  data-for="SketchBoardClose"
                                  className="tooltip position-relative toolbar__tag"
                                >
                                  <a
                                    href=""
                                    className="d-flex align-items-center icon-share"
                                    onClick={toggleSketchBoard}
                                  >
                                    <div className="pos-relative d-flex align-items-center justify-content-center ">
                                      <svg width="26px" viewBox="0 0 24 24">
                                        <path
                                          fill="#ffffff"
                                          d="M8.707 19.707 18 10.414 13.586 6l-9.293 9.293a1.003 1.003 0 0 0-.263.464L3 21l5.242-1.03c.176-.044.337-.135.465-.263zM21 7.414a2 2 0 0 0 0-2.828L19.414 3a2 2 0 0 0-2.828 0L15 4.586 19.414 9 21 7.414z"
                                        ></path>
                                      </svg>
                                      <svg
                                        className="edit-cut"
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth="0"
                                        viewBox="0 0 640 512"
                                        height="1em"
                                        width="1em"
                                      >
                                        <path d="M594.53 508.63L6.18 53.9c-6.97-5.42-8.23-15.47-2.81-22.45L23.01 6.18C28.43-.8 38.49-2.06 45.47 3.37L633.82 458.1c6.97 5.42 8.23 15.47 2.81 22.45l-19.64 25.27c-5.42 6.98-15.48 8.23-22.46 2.81z"></path>
                                      </svg>

                                      <ReactTooltip
                                        id={"SketchBoardClose"}
                                        place="top"
                                        effect="solid"
                                      >
                                        {"Pen Draw"}
                                      </ReactTooltip>
                                    </div>
                                  </a>
                                </div>
                              )}
                            </>
                          )}
                      </>

                      {/* <div
                      className={`tooltip position-relative toolbar__tag ${
                        isOpenWhiteBoard === true ? "bg-red" : ""
                      }`}
                      data-tip
                      data-for="registerTipwhiteBoard"
                    >
                      <a
                        href=""
                        className="icon-audio d-flex align-items-center"
                        onClick={toggleWhiteBoard}
                      >
                        <svg viewBox="2 3 20 18.6" width={25} height={25}>
                          <path
                            fill="#ffffff"
                            d="M20 3H4c-1.103 0-2 .897-2 2v11c0 1.103.897 2 2 2h4l-1.8 2.4 1.6 1.2 2.7-3.6h3l2.7 3.6 1.6-1.2L16 18h4c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM4 16V5h16l.001 11H4z"
                          ></path>
                          <path fill="#ffffff" d="M6 12h4v2H6z"></path>
                        </svg>

                        <ReactTooltip
                          id={"registerTipwhiteBoard"}
                          place="top"
                          effect="solid"
                        >
                          {globalStrings[selectedLanguage]?.$whiteboard}
                        </ReactTooltip>
                      </a>
                    </div> */}
                    </>
                  ) : null}
                </>
              ))}
            <div data-tip data-for="EndCall">
              <a
                href=""
                className="toolbar__tag bg-red icon-end"
                onClick={(event) => {
                  props?.callDetails?.monitor_status === undefined ||
                  props?.callDetails?.monitor_status === false
                    ? sendHangup(event, props, allAttendeeList)
                    : MonitorHangup(event, props, allAttendeeList);
                  store.dispatch(setIsOpenWhiteBoard(false));
                }}
              >
                <svg
                  className="rotate-135"
                  width="21px"
                  height="21px"
                  viewBox="3 3.19 17.81 17.81"
                >
                  <path
                    fill="#ffffff"
                    d="m20.487 17.14-4.065-3.696a1.001 1.001 0 0 0-1.391.043l-2.393 2.461c-.576-.11-1.734-.471-2.926-1.66-1.192-1.193-1.553-2.354-1.66-2.926l2.459-2.394a1 1 0 0 0 .043-1.391L6.859 3.513a1 1 0 0 0-1.391-.087l-2.17 1.861a1 1 0 0 0-.29.649c-.015.25-.301 6.172 4.291 10.766C11.305 20.707 16.323 21 17.705 21c.202 0 .326-.006.359-.008a.992.992 0 0 0 .648-.291l1.86-2.171a.997.997 0 0 0-.085-1.39z"
                  ></path>
                </svg>
                <ReactTooltip id={"EndCall"} place="top" effect="solid">
                  {"End"}
                </ReactTooltip>
              </a>
            </div>
          </div>
        </Draggable>
        {isOpenWhiteBoard && <CanvasUI />}
      </div>

      {kickOutAlert && (
        <AlertModal
          show={kickOutAlert}
          closeModal={() => setKickOutAlert(false)}
          msg={alertMsg}
          Remove={alertRemove}
          alertType="kickOut"
          selectedLanguage={selectedLanguage}
        />
      )}

      {openUploadModal && (
        <UploadFile
          setOpenUploadModal={setOpenUploadModal}
          openUploadModal={openUploadModal}
        />
      )}

      {desktopScreenShareShow && (
        <DesktopScreenShareModal
          show={desktopScreenShareShow}
          handleClose={() => store.dispatch(setDesktopScreenShareShow(false))}
          desktopSharingSources={desktopSharingSources}
          onSourceChoose={onSourceChoose}
        />
      )}
      <div>{audioContainer}</div>
    </>
  );
};

const MonitorHangup = (event, props, allAttendeeList) => {
  event.preventDefault();
  hangupOutBoundMonitorCall(props.callDetails, allAttendeeList);
};

const getVideoContainer = (
  props,
  kickOutHandler,
  pinUserId,
  setPinUserId,
  raiseHand,
  localDesktopVideoTrack,
  activeSpeaker,
  authSessionUser,
  allAttendeeList,
  sketchBoardIds,
  setSketchBoardIds,
  closeStage,
  localVideoTrack,
  isLatestVersion,
  galleryParticipantData,
  currentActiveVideoTracks,
) => {
  const videoTracks = getVideoTracks(currentActiveVideoTracks);
  const desktopTracks = getDesktopTracks(currentActiveVideoTracks);
  // const participantCount = galleryParticipantData.length + desktopTracks.length;
  const participantCount = galleryParticipantData.length;
  let innerCount =
    props?.callDetails?.monitor_status === undefined ||
    props?.callDetails?.monitor_status === false
      ? pinUserId != null
        ? 1
        : participantCount
      : desktopTracks.length;

  let videoClass = getVideoClass(innerCount);
  const companyid = authSessionUser.company_id;
  const userID = authSessionUser?.user_id;
  const localHandRaised =
    props?.callDetails?.participantID?.find(
      (findItem) => findItem.id === userID,
    )?.hand_raise ?? false;

  const notifySketchBoardClick = (user_id) => {
    const myUserId = authSessionUser?.user_id;
    if (sketchBoardIds) {
      setSketchBoardIds(null);
    } else {
      if (user_id === "local") {
        const userData = allAttendeeList.find(
          (filterItem) => filterItem.user_id === myUserId,
        );
      } else {
        setSketchBoardIds(user_id);
        const userData = allAttendeeList.find(
          (filterItem) => filterItem.user_id === user_id,
        );
        if (userData?.screenShareSource === "screen") {
          console.log("dd");
        }
      }
    }
  };
  const pinUserHandler = (v) => {
    setPinUserId((prevState) => {
      let newPinUserId = v;
      if (prevState === v) {
        setSketchBoardIds(null);
        newPinUserId = null;
      }
      return newPinUserId;
    });
  };
  transport.on("event", (event) => {
    if (
      event.name === "send-endpoint-text-message" &&
      localDesktopVideoTrack !== null &&
      localDesktopVideoTrack?.muted === false
    ) {
      const positions = event.data[0]?.pos;
      const start = event.data[0]?.start;
      const clearBoard = event.data[0]?.clearBoard;
      const data = {
        id: localDesktopVideoTrack.jitsiTrack.getSourceName(),
        color: color,
        company_id: companyid,
        user_id: userID,
        position: positions,
        clearBoard: clearBoard,
      };
      const ObjPayload = {
        senderID: authSessionUser?.user_id,
        event_type: "sketchBoard-Pos", // typing-start / typing-end / call-notification / msg-notification
        receiverID: props?.callDetails?.receiverID, // receiverID or groupID
        type: props?.callDetails?.type, // private or group
        company_id: props?.callDetails?.company_id,
        documentID: props?.callDetails?.documentID,
        data: data,
      };
      _sendEvent(ObjPayload);
      // _updateSketchBoardPosition(data);
    }
  });

  return (
    <>
      {galleryParticipantData.map((v) => {
        const id = v.jid;
        const displayName = v.name;
        let activeSpeakerClass = "";
        if (activeSpeaker === id) {
          activeSpeakerClass = "speaker";
        } else {
          activeSpeakerClass = "";
        }
        const { participantID, type } = props.callDetails;
        let handRaiseFlag = false;
        if (type === "private" && participantID.length === 0) {
          handRaiseFlag = props?.callDetails?.hand_raise ?? false;
        } else {
          handRaiseFlag =
            participantID?.find((findItem) => findItem.id === v.user_id)
              ?.hand_raise ?? false;
        }
        let audio_data = false;
        audio_data =
          participantID?.find((findItem) => findItem.id === v.user_id)?.audio ??
          false;

        let sketchBoard = false;
        if (sketchBoardIds === v.user_id) {
          sketchBoard = true;
        }
        const userData = allAttendeeList.find(
          (filterItem) => filterItem.user_id === v.user_id,
        );
        const screenShareSource = userData?.screenShareSource;

        const sharedScreenDrawEnabled =
          props?.callDetails?.entireScreenSharedIds !== undefined
            ? props?.callDetails?.entireScreenSharedIds.includes(v.user_id)
            : false;
        const spanKeyId = getKeyId(v.type, v.local, id);

        const isMonitorEnabled = props?.callDetails?.monitor_status ?? false;
        return (
          <>
            <span
              className={` ${videoClass} ${activeSpeakerClass}`}
              key={spanKeyId}
              id={spanKeyId}
              style={_getAvatarStyle(getAvatarColor(displayName))}
            >
              {v?.track !== null ? null : (
                <img
                  alt="profile"
                  src={profileDefault}
                  style={{ verticalAlign: "middle" }}
                  id={v.local === true ? "localprofile" : v + "profile"}
                />
              )}
              <div className="user-on-call-name">
                <h5 className="text-white m-0">{displayName}</h5>
                {!isMonitorEnabled && !audio_data && (
                  <a
                    className="mute-user-icon"
                    id={v + "audio"}
                    // style={{ display: "none" }}
                  >
                    <svg
                      width="15px"
                      height="15px"
                      viewBox="2.29 2 19.41 20.02"
                    >
                      <path
                        fill="#FFFFFF"
                        d="m21.707 20.293-3.388-3.388A7.942 7.942 0 0 0 20 12.021h-2a5.95 5.95 0 0 1-1.109 3.456l-1.452-1.452c.348-.591.561-1.27.561-2.004v-6C16 3.804 14.215 2 12.021 2c-.07 0-.14.009-.209.025A4.005 4.005 0 0 0 8 6.021v.565L3.707 2.293 2.293 3.707l18 18 1.414-1.414zM10 6.021c0-1.103.897-2 2-2a.918.918 0 0 0 .164-.015C13.188 4.08 14 4.956 14 6.021v6c0 .172-.029.335-.071.494L10 8.586V6.021zm-4 6H4c0 4.072 3.06 7.436 7 7.931v2.069h2v-2.07a7.993 7.993 0 0 0 2.218-.611l-1.558-1.558a5.979 5.979 0 0 1-1.66.239c-3.309 0-6-2.692-6-6z"
                      ></path>
                      <path
                        fill="#FFFFFF"
                        d="M8.011 12.132a3.993 3.993 0 0 0 3.877 3.877l-3.877-3.877z"
                      ></path>
                    </svg>
                  </a>
                )}
              </div>
              {v.local === false && props?.callDetails?.participantID ? (
                props?.callDetails?.participantID.length > 2 &&
                props.user_unique_key === props?.callDetails?.senderID ? (
                  <div className="icon__container position-absolute top-left p-3 index-3 d-flex icon-kickout">
                    <a
                      className="icon__tag bg-white d-flex align-items-center justify-content-center icon_container"
                      onClick={() => kickOutHandler(v?.user_id)}
                    >
                      <svg width="20" viewBox="3 3 18 18">
                        <path
                          fill="#b8c0c9"
                          d="M19.002 3h-14c-1.103 0-2 .897-2 2v4h2V5h14v14h-14v-4h-2v4c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V5c0-1.103-.898-2-2-2z"
                        ></path>
                        <path
                          fill="#b8c0c9"
                          d="m11 16 5-4-5-4v3.001H3v2h8z"
                        ></path>
                      </svg>
                    </a>
                  </div>
                ) : null
              ) : null}
              <div
                className={`icon__container  position-absolute top-left p-3 index-2 d-flex ${
                  props.user_unique_key === props?.callDetails?.senderID
                    ? "icon__container--first"
                    : ""
                }`}
              >
                <a
                  className="icon__tag  bg-white d-flex align-items-center justify-content-center mr-3 icon_container"
                  onClick={() =>
                    pinUserHandler(
                      v?.type === "camera"
                        ? v.local === true
                          ? "localVideo"
                          : id + "Video"
                        : v.local === true
                        ? "localscreen"
                        : id + "screen",
                    )
                  }
                >
                  {pinUserId === id + "Video" ||
                  pinUserId === "localVideo" ||
                  pinUserId === id + "screen" ||
                  pinUserId === "localscreen" ? (
                    <svg width="19" viewBox="1.31 0.72 13.97 13.97">
                      <path
                        fill="#b8c0c9"
                        d="M9.828.722a.5.5 0 0 1 .354.146l4.95 4.95a.5.5 0 0 1 0 .707c-.48.48-1.072.588-1.503.588-.177 0-.335-.018-.46-.039l-3.134 3.134a5.927 5.927 0 0 1 .16 1.013c.046.702-.032 1.687-.72 2.375a.5.5 0 0 1-.707 0l-2.829-2.828-3.182 3.182c-.195.195-1.219.902-1.414.707-.195-.195.512-1.22.707-1.414l3.182-3.182-2.828-2.829a.5.5 0 0 1 0-.707c.688-.688 1.673-.767 2.375-.72a5.922 5.922 0 0 1 1.013.16l3.134-3.133a2.772 2.772 0 0 1-.04-.461c0-.43.108-1.022.589-1.503a.5.5 0 0 1 .353-.146z"
                      ></path>
                    </svg>
                  ) : (
                    <svg width="19" viewBox="1.31 0.72 13.97 13.97">
                      <path
                        fill="#b8c0c9"
                        d="M9.828.722a.5.5 0 0 1 .354.146l4.95 4.95a.5.5 0 0 1 0 .707c-.48.48-1.072.588-1.503.588-.177 0-.335-.018-.46-.039l-3.134 3.134a5.927 5.927 0 0 1 .16 1.013c.046.702-.032 1.687-.72 2.375a.5.5 0 0 1-.707 0l-2.829-2.828-3.182 3.182c-.195.195-1.219.902-1.414.707-.195-.195.512-1.22.707-1.414l3.182-3.182-2.828-2.829a.5.5 0 0 1 0-.707c.688-.688 1.673-.767 2.375-.72a5.922 5.922 0 0 1 1.013.16l3.134-3.133a2.772 2.772 0 0 1-.04-.461c0-.43.108-1.022.589-1.503a.5.5 0 0 1 .353-.146zm.122 2.112v-.002.002zm0-.002v.002a.5.5 0 0 1-.122.51L6.293 6.878a.5.5 0 0 1-.511.12H5.78l-.014-.004a4.507 4.507 0 0 0-.288-.076 4.922 4.922 0 0 0-.765-.116c-.422-.028-.836.008-1.175.15l5.51 5.509c.141-.34.177-.753.149-1.175a4.924 4.924 0 0 0-.192-1.054l-.004-.013v-.001a.5.5 0 0 1 .12-.512l3.536-3.535a.5.5 0 0 1 .532-.115l.096.022c.087.017.208.034.344.034.114 0 .23-.011.343-.04L9.927 2.028c-.029.113-.04.23-.04.343a1.779 1.779 0 0 0 .062.46z"
                      ></path>
                    </svg>
                  )}
                </a>

                {((v.type === "desktop" &&
                  props?.callDetails?.monitor_status === undefined) ||
                  props?.callDetails?.monitor_status === false) &&
                isLatestVersion &&
                SCREENDRAW_ENABLE &&
                isElectron &&
                sharedScreenDrawEnabled &&
                pinUserId === id + "screen" ? (
                  <a
                    className="icon__tag  bg-white d-flex align-items-center justify-content-center mr-3 icon_container"
                    onClick={() => notifySketchBoardClick(v.user_id)}
                  >
                    {sketchBoard ? (
                      <svg width="19" viewBox="0 0 24 24">
                        <path
                          fill="#b8c0c9"
                          d="M8.707 19.707 18 10.414 13.586 6l-9.293 9.293a1.003 1.003 0 0 0-.263.464L3 21l5.242-1.03c.176-.044.337-.135.465-.263zM21 7.414a2 2 0 0 0 0-2.828L19.414 3a2 2 0 0 0-2.828 0L15 4.586 19.414 9 21 7.414z"
                        ></path>
                      </svg>
                    ) : (
                      <svg width="19" viewBox="0 0 24 24">
                        <path
                          fill="#b8c0c9"
                          d="M4 21a1 1 0 0 0 .24 0l4-1a1 1 0 0 0 .47-.26L21 7.41a2 2 0 0 0 0-2.82L19.42 3a2 2 0 0 0-2.83 0L4.3 15.29a1.06 1.06 0 0 0-.27.47l-1 4A1 1 0 0 0 3.76 21 1 1 0 0 0 4 21zM18 4.41 19.59 6 18 7.59 16.42 6zM5.91 16.51 15 7.41 16.59 9l-9.1 9.1-2.11.52z"
                        ></path>
                      </svg>
                    )}
                  </a>
                ) : null}

                {v.type === "camera" &&
                props.user_unique_key === props?.callDetails?.senderID &&
                handRaiseFlag === true ? (
                  <a
                    className="icon__tag bg-white  d-flex align-items-center justify-content-center ml-2 icon_container"
                    onClick={(e) => raiseHand(e, props, v.user_id, false)}
                  >
                    <svg width="20" viewBox="2 2 20 20">
                      <path
                        fill="#b8c0c9"
                        d="M20.5 5A1.5 1.5 0 0 0 19 6.5V11h-1V4.5a1.5 1.5 0 0 0-3 0V11h-1V3.5a1.5 1.5 0 0 0-3 0V11h-1V5.5a1.5 1.5 0 0 0-3 0v10.81l-2.22-3.6a1.5 1.5 0 0 0-2.56 1.58l3.31 5.34A5 5 0 0 0 9.78 22H17a5 5 0 0 0 5-5V6.5A1.5 1.5 0 0 0 20.5 5z"
                      ></path>
                    </svg>
                  </a>
                ) : null}
              </div>

              {v.track !== null
                ? v.local === true
                  ? getVideoElement(v.track)
                  : v.type === "camera"
                  ? getVideoElement(v.track)
                  : getCanvasVideoElement(
                      v.track,
                      sketchBoard,
                      v.user_id,
                      screenShareSource,
                      pinUserId,
                      props?.fullScreen,
                      closeStage,
                    )
                : null}
            </span>
          </>
        );
      })}

      <Pagination_custom pinUserId={pinUserId} />
    </>
  );
};
const getAudioContainer = (isActiveSpeaker) => {
  const audioTracks = !isActiveSpeaker ? [] : getAudioTracks();
  return audioTracks.map((t) => {
    return getAudioElement(t.jitsiTrack);
  });
};
const getVideoClass = (innerCount) => {
  let videoClass = null;
  if (innerCount === 1) {
    videoClass = "video__container__box active_speaker_video__container__box";
  }
  if (innerCount === 2) {
    videoClass = "video__container__box two_video__container__box";
  }
  if (innerCount === 3) {
    videoClass = "video__container__box twoBYtwo_video__container__box";
  }
  if (innerCount === 4) {
    videoClass =
      "video__container__box twoBYtwo_video__container__box four_part";
  }
  if (innerCount === 5) {
    videoClass = "video__container__box threeBYtwo_video__container__box";
  }
  if (innerCount === 6) {
    videoClass =
      "video__container__box threeBYtwo_video__container__box six_part";
  }
  if (innerCount === 7 || innerCount === 8 || innerCount === 9) {
    videoClass = "video__container__box threeBYthree_video__container__box";
  }
  if (innerCount === 10 || innerCount === 11 || innerCount === 12) {
    videoClass = "video__container__box fourBYthree_video__container__box";
  }
  if (
    innerCount === 13 ||
    innerCount === 14 ||
    innerCount === 15 ||
    innerCount === 16
  ) {
    videoClass = "video__container__box fourBYfour_video__container__box";
  }
  if (
    innerCount === 17 ||
    innerCount === 18 ||
    innerCount === 19 ||
    innerCount === 20
  ) {
    videoClass = "video__container__box fiveBYfour_video__container__box";
  }
  if (
    innerCount === 21 ||
    innerCount === 22 ||
    innerCount === 23 ||
    innerCount === 24 ||
    innerCount === 25
  ) {
    videoClass = "video__container__box fiveBYfive_video__container__box";
  }
  return videoClass;
};
const getCanvasVideoElement = (
  track,
  startEdit,
  userId,
  screenShareSource,
  pinUserId,
  fullScreen,
  closeStage,
) => {
  let videoTrack = { jitsiTrack: track };

  return (
    <>
      <div className="draw-layout">
        <SketchBoard
          startEdit={startEdit}
          userId={userId}
          id={track.getSourceName()}
          screenShareSource={screenShareSource}
          videoID={`video_${track.getId()}`}
          color={color}
          pinUserId={pinUserId}
          fullScreen={fullScreen}
          closeStage={closeStage}
        />

        <Video
          key={`video_${track.getId()}`}
          videoTrack={videoTrack}
          className="object-fit-fill"
          autoPlay={true}
          id={`video_${track.getId()}`}
          playsinline={true}
          hidden={true}
        />
      </div>
    </>
  );
};
const getVideoElement = (track, type) => {
  let videoTrack = { jitsiTrack: track };

  return (
    <Video
      key={`video_${track.getId()}`}
      videoTrack={videoTrack}
      className=""
      autoPlay={true}
      id={`video_${track.getId()}`}
      playsinline={true}
    />
  );
};
const getAudioElement = (track) => {
  let audioTrack = { jitsiTrack: track };
  return (
    <Audio
      key={`audio_${track.getId()}`}
      audioTrack={audioTrack}
      autoPlay={true}
      id={`audio_${track.getId()}`}
    />
  );
};

const toggleVideo = (e) => {
  e.preventDefault();
  const isActiveCamera = store.getState().lib.isActiveCamera;
  if (isActiveCamera) {
    localVideoTrackMute();
  } else {
    localVideoTrackUnMute();
  }
};

const toggleSpeaker = (e) => {
  e.preventDefault();
  const isActiveSpeaker = store.getState().lib.isActiveSpeaker;
  store.dispatch(setisActiveSpeaker(!isActiveSpeaker));
};

const toggleAudio = (e) => {
  e.preventDefault();
  const isActiveMic = store.getState().lib.isActiveMic;
  if (isActiveMic) {
    muteMic();
  } else {
    localAudioTrackUnMute();
  }
};

const raiseHand = (e, props, userId, handRaiseValue) => {
  e.preventDefault();

  const updatedCallDetail = { ...props.callDetails };
  const { participantID, type } = updatedCallDetail;
  if (type === "private" && participantID.length === 0) {
    updatedCallDetail["hand_raise"] = handRaiseValue;
    _callDocUpdate(updatedCallDetail);
  } else {
    const updatedParticipantID = [...participantID];
    let index = participantID.findIndex((v) => v.id === userId);

    const particularObject = { ...updatedParticipantID[index] };
    particularObject.hand_raise = handRaiseValue;
    const callParticipantsPayload = {
      company_id: updatedCallDetail?.company_id,
      id: updatedCallDetail?.id,
      participantID: particularObject,
      receiverID: updatedCallDetail?.receiverID,
    };

    _updateCallParticipants(callParticipantsPayload);
  }
};

const toggleScreenShare = async (event) => {
  event.preventDefault();
  const isActiveScreenSharing = store.getState().lib.isActiveScreenSharing;
  if (!isActiveScreenSharing) {
    window.JitsiMeetScreenObtainer = {
      openDesktopPicker(options, onSourceChoose) {
        showDesktopPickerConference(options, onSourceChoose);
      },
    };
    startscreenshare();
  } else if (isActiveScreenSharing) {
    stopscreenshare();
  }
};

const toggleRecording = async (event, props) => {
  event.preventDefault();

  const { callDetails } = props;
  const isActiveRecording = store.getState().lib.isActiveRecording;
  if (!isActiveRecording) {
    startrecording();
    const updatedCallDetail = { ...props.callDetails };
    updatedCallDetail["recorderUserId"] = props.user_unique_key;
    _callDocUpdate(updatedCallDetail);
  } else if (isActiveRecording) {
    stoprecording();
    const updatedCallDetail = { ...props.callDetails };
    updatedCallDetail["recorderUserId"] = "";
    _callDocUpdate(updatedCallDetail);
  }
};

function showDesktopPickerConference(options, onSourceChoose) {
  const { desktopSharingSources } = options;
  setDesktopScreenShareSource(desktopSharingSources, onSourceChoose);
}
export default VideoContainerHtml;
