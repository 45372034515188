/* eslint-disable indent */
import io from "socket.io-client";

import store from "./store";
import {
  setErrorCode,
  setIsLoggedInAccountActive,
  setLogout,
} from "../src/component/redux/authUserDetail";
import { SOCKET_URL } from "./constants";
export let socket = null;
import {
  getAuthSession,
  // getMyUserId,
  getUpdateUnreadChatMessage,
  isElectron,
} from "./component/Helpers/common";
import {
  addScheduledCall,
  addScheduledCallForHost,
  addWhiteBoardData,
  removeScheduledCall,
  setAllAttendeeList,
  setCallDetail,
  setScheduledGroup,
  setWhiteBoardData,
  setSketchBoardPosition,
  setMonitorScreeens,
  setMuteAllLoader,
} from "./component/redux/callSlice";
import {
  setLoderAttendeeDirect,
  setUnreadChatMessage,
  setChatMessage,
  setIsFetchingChatHistory,
  setDeleteChatData,
  setLoderAttendeeGroup,
  setSocketData,
  setIsSocketConnected,
  setGroupList,
} from "./component/redux/chatSlice";
import {
  startScheduledCall,
  raiseHandNotify,
  scheduledCallReminder,
  getParticipiantsJidSortedData,
  IndividualUserActionPerform,
  // callSetupHandler,
} from "./component/Helpers/CallCommon";
import {
  addchatHistoryIndexDb,
  getMultipleImagesData,
  updateMessageIndexDb,
  userAccountExist,
} from "./component/Helpers/chatCommon";
import swal from "sweetalert";
import { getScreensInfo } from "./component/Helpers/Lib/screenShareInfo";
import * as Sentry from "@sentry/react";
import { addDataToDB } from "./component/util/indexedDB";

export const _socketConnect = (paramObj) => {
  const { dispatch } = store;
  return new Promise((resolve) => {
    socket = io(SOCKET_URL, {
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 99999,
    });
    socket.on("connect", async () => {
      console.log("CONNECT");
      dispatch(setIsSocketConnected(true));
      dispatch(setSocketData(socket));
      if (socket.connected) {
        const tokenRes = await _sendToken({ ...paramObj });
        return resolve(tokenRes);
      }
    });
    socket.on("reconnect", async () => {
      console.log("RECONNECT");
      if (socket.connected) {
        dispatch(setIsSocketConnected(true));
        dispatch(setSocketData(socket));
        const { login_token } = getAuthSession();
        userAccountExist(login_token);
      }
    });
    socket.on("disconnect", (reason) => {
      // dispatch(setIsSocketConnected(false));
      console.error("disconnect", reason);
      const state = store.getState();
      const { authSessionUser } = state.authUserDetail;
      if (authSessionUser?.onCall === true) {
        Sentry.captureEvent({
          message: "API socket disconnected",
          level: "info",
          extra: {
            additionalData: {
              name: authSessionUser?.name,
              company_id: authSessionUser?.company_id,
              roomId: authSessionUser?.roomId,
              user_id: authSessionUser?.user_id,
              username: authSessionUser?.username,
              onCall: authSessionUser?.onCall,
            },
          },
        });
      }
    });
    socket.on("connect_error", (error) => {
      // dispatch(setIsSocketConnected(false));
      console.log("connect_error", error);
    });
    // socket.on("sketchBoard-Pos", (data) => {
    //   dispatch(setSketchBoardPosition(data));
    // });
  });
};

export const _sendToken = (tokenObj) => {
  const storeToken = store.getState().authUserDetail.userLoginToken;
  let newTokenObj = {};
  if (tokenObj != undefined) {
    if (tokenObj.unique_token === null) {
      newTokenObj["unique_token"] = storeToken;
    } else {
      newTokenObj = { ...tokenObj };
    }
  } else {
    newTokenObj["unique_token"] = storeToken;
  }
  return new Promise((resolve) => {
    socket.emit(
      "con",
      {
        doc: newTokenObj,
      },
      (response) => {
        return resolve(response);
      },
    );
  });
};

export const _getVerifyTokenData = (cb) => {
  socket.on("con", (doc) => {
    cb(doc);
  });
};

export const _getUsers = () => {
  const { dispatch } = store;
  socket.on("users", (doc) => {
    store.dispatch(setAllAttendeeList(doc));
    dispatch(setLoderAttendeeDirect(false));
  });
};

export const _getGroups = () => {
  const { dispatch } = store;
  socket.on("groups", (data) => {
    const Groups = data.filter(
      (filterItem) => filterItem.group_active === true,
    );
    dispatch(setGroupList(Groups));
    dispatch(setLoderAttendeeGroup(false));
  });
};

export const _userAdd = (userData) => {
  socket.emit("user-add", {
    doc: userData,
  });
};

// export const _updateSketchBoardPosition = (data) => {
//   socket.emit("sketchBoard-Pos", {
//     doc: data,
//   });
// };
export const _getUserAddedData = () => {
  socket.on("user-add", (data) => {
    const state = store.getState();
    const { allAttendeeList } = state.call;
    const newUsersList = [...allAttendeeList];
    newUsersList.push(data);
    store.dispatch(setAllAttendeeList(newUsersList));
  });
};

export const _userUpdate = (userData) => {
  socket.emit("user-upd", {
    doc: userData,
  });
};

export const _getUserUpdatedData = () => {
  socket.on("user-upd", (data) => {
    const state = store.getState();
    const { allAttendeeList } = state.call;
    const { authSessionUser, logout } = state.authUserDetail;
    const prevUsers = allAttendeeList;
    let newUsersList = [...allAttendeeList];
    if (authSessionUser?.user_id === data?.user_id) {
      //Get user index
      const userIndex = prevUsers.findIndex(
        (obj) => obj.user_id === data?.user_id,
      );
      newUsersList[userIndex] = data; // update data
      if (!logout) {
        if (data.user_active === false) {
          store.dispatch(setIsLoggedInAccountActive(true));
          store.dispatch(setErrorCode(403));
        } else {
          store.dispatch(setIsLoggedInAccountActive(false));
          store.dispatch(setErrorCode(""));
        }
      }
    } else {
      if (data.user_active === true) {
        const userIndex = prevUsers.findIndex(
          //Get user index
          (obj) => obj.user_id === data?.user_id,
        );
        if (userIndex !== -1) {
          newUsersList[userIndex] = data; // update data
        }
      } else {
        newUsersList = prevUsers.filter(
          // remove group from list
          (filterItem) => filterItem.user_id !== data?.user_id,
        );
      }
    }
    store.dispatch(setAllAttendeeList(newUsersList));
  });
};

export const _userRemove = (userData) => {
  socket.emit("user-rem", {
    doc: userData,
  });
};

export const _getAfterUserRemovedData = () => {
  socket.on("user-rem", (data) => {
    const state = store.getState();
    const { allAttendeeList } = state.call;
    const prevUsersList = [...allAttendeeList];
    const newUsersList = prevUsersList.filter(
      // remove user from list
      (filterItem) => filterItem.user_id !== data?.user_id,
    );
    store.dispatch(setAllAttendeeList(newUsersList));
  });
};

export const _groupAdd = (groupData) => {
  socket.emit("group-add", {
    doc: groupData,
  });
};

export const _getGroupAddedData = () => {
  socket.on("group-add", (data) => {
    const state = store.getState();
    const { dispatch } = store;
    const { groupList } = state.chat;
    const newGroupsList = [...groupList];
    newGroupsList.unshift(data); // added new group into list at first position
    dispatch(setGroupList(newGroupsList));
  });
};

export const _groupUpdate = (groupData) => {
  socket.emit("group-upd", {
    doc: groupData,
  });
};

export const _groupUsersRemove = (groupData) => {
  socket.emit("group-rem-id", {
    doc: groupData,
  });
};

export const _getGroupUpdatedData = () => {
  socket.on("group-upd", (data) => {
    const state = store.getState();
    const { groupList } = state.chat;
    const { dispatch } = store;

    let newGroupList = [...groupList];
    if (data.group_active === true) {
      const groupIndex = groupList.findIndex(
        // get index of group
        (obj) => obj.groupId === data.groupId,
      );
      if (groupIndex === -1) {
        newGroupList.push(data);
      } else {
        newGroupList[groupIndex] = data; // update group data using index
      }
    } else {
      newGroupList = groupList.filter(
        // remove group from list
        (filterItem) => filterItem.groupId !== data.groupId,
      );
    }
    dispatch(setGroupList(newGroupList));
  });
};

export const _groupRemove = (groupData) => {
  socket.emit("group-rem", {
    doc: groupData,
  });
};

export const _getAfterGroupRemovedData = () => {
  socket.on("group-rem", (data) => {
    const state = store.getState();
    const { dispatch } = store;
    const { groupList } = state.chat;

    const prevGroupList = [...groupList];
    const newGroupList = prevGroupList.filter(
      // remove group from list
      (filterItem) => filterItem.groupId !== data.groupId,
    );
    dispatch(setGroupList(newGroupList));
  });
};

export const _sendChatMessage = (chatData) => {
  socket.emit(
    "chat",
    {
      doc: chatData,
    },
    (response) => {
      console.log("_sendChatMessage response", response); // ok
    },
  );
};

export const _getUpdatedChatMessage = (user_id) => {
  socket.on("chat", (data) => {
    const state = store.getState();
    const {
      chatRoom,
      isChatHistoryEnableOnce,
      isScrollingUp,
      unreadChatMessage,
    } = state.chat;
    const roomId = chatRoom;

    const newChat = [...store.getState().chat.chatMessage];
    const findIndex = newChat.findIndex((findItem) => findItem.id === data.id);

    if (data.edited === false) {
      //---------------------user should be on top which either receive message or send message concept code end -------------------------//
      if (data.senderID === user_id) {
        getUpdateUnreadChatMessage(data.receiverID, "lastUserActivity");
      }

      if (roomId !== data.documentID) {
        if (data?.deleted === false) {
          if (data.type === "private") {
            if (data.receiverID === user_id) {
              getUpdateUnreadChatMessage(data.senderID, "countUpdate");
            }
          } else {
            if (data.senderID !== user_id) {
              const groupMuteData = store.getState().chat.unreadChatMessage;
              const groupMuteElement = groupMuteData.filter(
                (filterItem) =>
                  filterItem.id === data.receiverID ||
                  filterItem.user_id === data.receiverID,
              );
              if (groupMuteElement.length > 0) {
                const groupMuteValue = groupMuteElement[0]?.group_mute ?? false;
                const message = data.message;
                const isALLTagged = message?.match("all_c2p_tagged");
                if (
                  groupMuteValue === false ||
                  (isALLTagged !== null && isALLTagged.length > 0)
                ) {
                  getUpdateUnreadChatMessage(data.receiverID, "countUpdate");
                } else {
                  const message = data.message;
                  const isUserTagged = message?.match(user_id);
                  if (isUserTagged !== null && isUserTagged.length > 0) {
                    getUpdateUnreadChatMessage(data.receiverID, "countUpdate");
                  }
                }
              } else {
                getUpdateUnreadChatMessage(data.receiverID, "countUpdate");
              }
            }
          }
        } else {
          if (data.id) {
            const updatedUnreadChatMessage = unreadChatMessage.filter(
              (item) => item.id !== data.id,
            );
            store.dispatch(setUnreadChatMessage(updatedUnreadChatMessage));
          }
        }
      }
    }

    if (roomId === data.documentID) {
      // validate received data should be corresponding to selected room
      if (findIndex === -1) {
        newChat.unshift(data);
        const tmpArray = [];
        tmpArray.push(data);
        getMultipleImagesData(tmpArray);
        if (isChatHistoryEnableOnce || isScrollingUp) {
          if (data?.deleted === false) {
            if (data.type === "private") {
              if (data.receiverID === user_id) {
                getUpdateUnreadChatMessage(data.senderID, "countUpdate");
              }
            } else {
              if (data.senderID !== user_id) {
                getUpdateUnreadChatMessage(data.receiverID, "countUpdate");
              }
            }
          }
        }
      } else {
        if (!data?.deleted) {
          newChat[findIndex] = data;
        } else {
          store.dispatch(setDeleteChatData(data));
          newChat.splice(findIndex, 1);
        }
      }
    }

    store.dispatch(setChatMessage(newChat));
    updateMessageIndexDb(data);
  });
};

export const _sendChatRoomDetail = (chatRoomData) => {
  socket.emit("chat-list", {
    doc: chatRoomData,
  });
};

export const _getMessageList = (
  cb,
  user_unique_key,
  chatRoomDetail,
  off = false,
) => {
  if (!off) {
    socket.on("chat-list", (data) => {
      // if (data.length > 0) {
      //   if (chatRoomDetail === data[0].documentID) {
      //     // validate received data should be corresponding to selected room
      //     if (data[0].type === "private") {
      //       if (
      //         data[0].senderID === user_unique_key ||
      //         data[0].receiverID === user_unique_key
      //       ) {
      //         store.dispatch(setChatMessage(data));
      //       }
      //     } else {
      //       store.dispatch(setChatMessage(data));
      //     }
      //     getMultipleImagesData(data);
      //   }
      // } else {
      //   store.dispatch(setChatMessage(data));
      // }

      if (data.length > 0) {
        if (chatRoomDetail === data[0].documentID) {
          if (data.length > 0) {
            const payload = {
              id: chatRoomDetail,
              list: data,
            };
            addDataToDB(payload);
            store.dispatch(setChatMessage(data));
            getMultipleImagesData(data);
          } else {
            store.dispatch(setChatMessage([]));
          }
        }
      } else {
        store.dispatch(setChatMessage([]));
      }
    });
  } else {
    socket.removeAllListeners("chat-list");
    cb();
  }
};

export const _sendEvent = (eventData) => {
  socket.emit("event", {
    doc: eventData,
  });
};

export const _getEventList = (
  user_unique_key,
  setPrivateTypingDaa,
  setGroupTypingDaa,
  setNotificationData,
) => {
  socket.on("event", (data) => {
    if (
      data.event_type === "typing-start" ||
      data.event_type === "typing-end"
    ) {
      if (data.type === "private") {
        if (data.receiverID === user_unique_key) {
          setPrivateTypingDaa((preTypingData) => {
            const newTyping = [...preTypingData];
            const TypingIndex = preTypingData.findIndex(
              (obj) => obj.receiverID === data.receiverID,
            );
            if (TypingIndex != -1) {
              newTyping[TypingIndex] = data;
            } else {
              newTyping.push(data);
            }
            return newTyping;
          });
        }
      } else {
        setGroupTypingDaa((preTypingData) => {
          const newTyping = [...preTypingData];
          const TypingIndex = preTypingData.findIndex(
            (obj) => obj.receiverID === data.receiverID,
          );
          if (TypingIndex != -1) {
            newTyping[TypingIndex] = data;
          } else {
            newTyping.push(data);
          }
          return newTyping;
        });
      }
    } else if (
      data.event_type === "call-notification" ||
      data.event_type === "msg-notification"
    ) {
      if (data.type === "private") {
        if (data.receiverID === user_unique_key) {
          setNotificationData(data);
        }
      } else {
        if (data.senderID !== user_unique_key) {
          setNotificationData(data);
        }
      }
    } else if (data.event_type === "mute-all") {
      if (user_unique_key !== data?.senderID) {
        store.dispatch(setMuteAllLoader(true));
        setTimeout(() => {
          store.dispatch(setMuteAllLoader(false));
        }, 3000);
        IndividualUserActionPerform("mute");
      }
    } else if (data.event_type === "sketchBoard-Pos") {
      store.dispatch(setSketchBoardPosition(data.data));
    }
  });
};

export const _updateCall = (updateCallDetail) => {
  let payload = { ...updateCallDetail };
  socket.emit("call", {
    doc: payload,
  });
};

export const _updatedCallDetail = () => {
  const { dispatch } = store;
  socket.on("call", (data) => {
    let newDetail = { ...store.getState().call.callDetail };
    let prevData = store.getState().call.callDetail;
    const state = store.getState();
    const { authSessionUser } = state.authUserDetail;
    if (Object.keys(prevData).length > 0) {
      if (prevData.type === "private") {
        if (
          prevData.calling_status === "ended" ||
          prevData.calling_status === "decline"
        ) {
          newDetail = data;
        } else {
          if (prevData.id === data.id) {
            newDetail = data;
          } else if (prevData.user_added === true) {
            // eslint-disable-next-line no-unsafe-optional-chaining
            const tmpUserData = prevData?.participantID?.find(
              (v) => v.id === authSessionUser?.user_id,
            );
            if (
              tmpUserData?.status === "decline" ||
              tmpUserData?.status === "ended"
            ) {
              newDetail = data;
            }
          }
        }
      } else {
        if (prevData.calling_status === "ended") {
          newDetail = data;
        } else {
          if (prevData.id === data.id) {
            newDetail = data;
          } else {
            // eslint-disable-next-line no-unsafe-optional-chaining
            const tmpPartcularUserData = prevData?.participantID?.find(
              (v) => v.id === authSessionUser?.user_id,
            );
            if (
              tmpPartcularUserData?.status === "decline" ||
              tmpPartcularUserData?.status === "ended"
            ) {
              newDetail = data;
            }
          }
        }
      }
    } else {
      newDetail = data;
    }

    const participantID = newDetail?.participantID
      ? [...newDetail.participantID]
      : [];
    const sortedList = getParticipiantsJidSortedData(participantID);
    newDetail["participantID"] = sortedList;
    dispatch(setCallDetail(newDetail));

    /// scheduled call list manage
    const { scheduledCallList } = state.call;
    if (data?.scheduleId) {
      const scheduleCallIndex = scheduledCallList.findIndex(
        (item) => item.scheduleId === data?.documentID,
      );
      // eslint-disable-next-line no-unsafe-optional-chaining
      const tmpPartcularUserData = data?.participantID?.find(
        (v) => v.id === authSessionUser?.user_id,
      );
      if (scheduleCallIndex === -1) {
        if (
          data?.calling_status === "initiated" &&
          (tmpPartcularUserData?.status === "decline" ||
            tmpPartcularUserData?.status === "ended")
        ) {
          dispatch(
            addScheduledCall({
              scheduleId: data?.scheduleId,
              scheduleName: data?.groupName,
            }),
          );
        }
      } else {
        if (data?.calling_status === "ended") {
          dispatch(
            removeScheduledCall({
              scheduleId: data?.documentID,
              scheduleName: data?.groupName,
            }),
          );
        } else {
          if (tmpPartcularUserData?.status === "accepted") {
            dispatch(
              removeScheduledCall({
                scheduleId: data?.documentID,
                scheduleName: data?.groupName,
              }),
            );
          }
        }
      }
    }
  });
};

// export const _sendRoom = (roomDetail) => {
//   socket.emit("call-doc", {
//     doc: roomDetail,
//   });
// };

export const _getRoomDetail = (off = false) => {
  if (!off) {
    let roomDetail = new Promise((resolve) => {
      socket.on("call-doc", (data) => {
        return resolve(data);
      });
    });
    return roomDetail;
  } else {
    socket.removeAllListeners("call-doc");
  }
};

export const _unreadMessageCountUpdate = (unreadMessageCountData) => {
  socket.emit("unread-upd", {
    doc: unreadMessageCountData,
  });
};

export const _sendUnreadMessageCountList = (data) => {
  socket.emit("unread-list", {
    doc: data,
  });
};

export const _getUnreadMessageCountList = () => {
  socket.on("unread-list", (data) => {
    store.dispatch(setUnreadChatMessage(data));
  });
};

export const _getKeepAlive = () => {
  socket.on("keep-alive", (data) => {
    console.log("_getKeepAlive", data);
  });
};

export const _sendLoadMoreChatData = (data) => {
  socket.emit("chat-history", {
    doc: data,
  });
};

export const _getLoadMoreChatData = () => {
  socket.on("chat-history", (data) => {
    const prevChatData = [...store.getState().chat.chatMessage];
    prevChatData.push(...data);
    getMultipleImagesData(data);
    store.dispatch(setChatMessage(prevChatData));
    store.dispatch(setIsFetchingChatHistory(false));
    addchatHistoryIndexDb(data);
  });
};

export const _editChatMessage = (chatData) => {
  socket.emit("chat-edit", {
    doc: chatData,
  });
};

export const _deleteChatMessage = (chatData) => {
  socket.emit("chat-delete", {
    doc: chatData,
  });
};

export const _searchChatMessage = (chatData) => {
  socket.emit("chat-filter", {
    doc: chatData,
  });
};

export const _getSearchChatMessage = () => {
  socket.on("chat-filter", (data) => {
    store.dispatch(setChatMessage(data));
  });
};

export const _getLogout = () => {
  socket.on("logout", () => {
    store.dispatch(setLogout(true));
  });
};

export const _getInstantCall = () => {
  socket.on("instant-call", (data) => {
    console.log("instant-call", data);
    const { user_unique_key } = getAuthSession();
    store.dispatch(setScheduledGroup(data));
    const state = store.getState();
    const { allAttendeeList } = state.call;
    if (user_unique_key === data.adminId) {
      const adminData =
        allAttendeeList?.find((item) => item.user_id === data.adminId) ?? {};
      if (!adminData?.onCall) {
        swal({
          text: `You have scheduled a call with the name "${data?.scheduleName}". Do you want to start the call?`,
          dangerMode: true,
          buttons: { cancel: "Cancel ", later: "Later", confirm: "Start" },
          closeOnClickOutside: false,
        }).then((swalType) => {
          if (swalType === true) {
            startScheduledCall(data);
          } else if (swalType === "later") {
            store.dispatch(addScheduledCallForHost(data));
          }
        });
      }
    }
  });
};

export const _jumpToSpecificChatData = (doc) => {
  return new Promise((resolve) => {
    socket.emit("jump-to-day", { doc }, (res) => {
      return resolve(res);
    });
  });
};

export const clearUnreadCount = (doc) => {
  return new Promise((resolve) => {
    socket.emit("clear-unread-count", { doc }, (res) => {
      return resolve(res);
    });
  });
};

export const whiteBoardElemets = (doc) => {
  socket.emit("wb-event-list", { doc });
};

export const getWhiteBoardElemets = () => {
  socket.on("wb-event-list", (data) => {
    const { dispatch } = store;
    dispatch(setWhiteBoardData(data));
  });
};

export const addWhiteBoardElemets = (doc) => {
  const state = store.getState();
  const { callDetail } = state.call;
  const { company_id, documentID, type, participantID, senderID, receiverID } =
    callDetail;
  let attendeeId = [];
  if (type === "group") {
    attendeeId = participantID.map((a) => a.id);
  } else {
    attendeeId.push(senderID);
    attendeeId.push(receiverID);
  }

  const payload = {
    company_id,
    documentID,
    attendeeId,
    ...doc,
  };
  socket?.emit("wb-event-add", { doc: payload });
};

export const getAddedWhiteBoardElemets = () => {
  socket.on("wb-event-add", (data) => {
    // const user_id = getMyUserId();
    const { dispatch } = store;
    // if (user_id !== data.source) {
    dispatch(addWhiteBoardData(data));
    // }
  });
};
export const updateWhiteBoardElemets = (doc) => {
  socket.emit("wb-event-upd", { doc });
};

export const getUpdateWhiteBoardElemets = () => {
  socket.on("wb-event-upd", (data) => {
    const { dispatch } = store;
    dispatch(addWhiteBoardData(data));
  });
};

export const clearWhiteBoardData = () => {
  const state = store.getState();
  const { callDetail } = state.call;
  const { company_id, documentID, type, participantID, senderID, receiverID } =
    callDetail;
  let attendeeId = [];

  if (type === "group") {
    attendeeId = participantID.map((a) => a.id);
  } else {
    attendeeId.push(senderID);
    attendeeId.push(receiverID);
  }
  const payload = {
    company_id,
    documentID,
    attendeeId,
  };
  socket.emit("wb-event-clear", { doc: payload });
};

export const getClearWhiteBoardData = () => {
  socket.on("wb-event-clear", (data) => {
    const { dispatch } = store;
    dispatch(setWhiteBoardData(data));
  });
};

export const raiseHandNotification = () => {
  const state = store.getState();
  const { callDetail } = state.call;
  const { authSessionUser } = state.authUserDetail;
  const { user_id, name } = authSessionUser;
  const {
    company_id,
    documentID,
    type,
    participantID,
    senderID,
    receiverID,
    user_added,
  } = callDetail;
  let attendeeId = [];

  if (type === "group" || (type === "private" && user_added === true)) {
    attendeeId = participantID
      .filter((filterItem) => filterItem?.id !== user_id)
      .map((a) => a.id);
  } else {
    if (user_id !== senderID) {
      attendeeId.push(senderID);
    }
    if (user_id !== receiverID) {
      attendeeId.push(receiverID);
    }
  }
  const payload = {
    company_id,
    documentID,
    attendeeId,
    name,
  };
  socket.emit("raise-hand-notification", { doc: payload });
};

export const getRaiseHandNotificaton = () => {
  socket.on("raise-hand-notification", (data) => {
    raiseHandNotify(data);
  });
};

export const _getInstantCallReminder = () => {
  socket.on("instant-call-reminder", (data) => {
    swal({
      text: `You have scheduled a call with the name "${data?.scheduleName.trim()}" in ${
        data?.reminder_minutes ?? 5
      } minutes.`,
      dangerMode: true,
      buttons: {
        cancel: false,
        Ok: true,
      },
      closeOnClickOutside: false,
    });
    scheduledCallReminder(data);
  });
};

export const _updateRecentlyUsedEmoticons = (payload) => {
  socket.emit("emoji-upd", { doc: payload });
};

export const _saveLogs = (payload) => {
  socket.emit("logs", { doc: payload });
};

export const _sendScreenInfoNotify = (payload) => {
  socket.emit("screens-info-notify", { doc: payload });
};

export const _getScreenInfoNotify = () => {
  socket.on("screens-info-notify", (data) => {
    if (isElectron) {
      getScreensInfo({
        company_id: data?.company_id,
        sender_id: data?.sender_id,
      });
    }
  });
};

export const _sendScreenInfo = (payload) => {
  socket.emit("get-screens-info", { doc: payload });
};

export const _getScreenInfo = () => {
  socket.on("get-screens-info", (data) => {
    store.dispatch(setMonitorScreeens(data?.screens));
  });
};

export const _desktopSystemStats = (payload) => {
  socket.emit("desktop-system-stats", { doc: payload });
};

export const _updateCallParticipants = (payload) => {
  socket.emit("call-att-upd", { doc: payload });
};

export const _getCallParticipants = () => {
  socket.on("call-att-upd", (data) => {
    const state = store.getState();
    const { callDetail } = state.call;

    if (callDetail?.id === data.id) {
      const { participantID } = callDetail;
      let participant = Array.from(participantID);
      let index = participantID.findIndex(
        (v) => v.id === data?.participantID?.id,
      );
      let updatedCallDetails = { ...callDetail };
      participant[index] = data?.participantID;
      updatedCallDetails["participantID"] = participant;
      store.dispatch(setCallDetail(updatedCallDetails));
    }
  });
};

export const _callDocUpdate = (payload) => {
  socket.emit("call-upd", { doc: payload });
};

export const _getCallDocUpdate = () => {
  socket.on("call-upd", (data) => {
    const state = store.getState();
    const { callDetail } = state.call;

    if (callDetail?.id === data.id) {
      const updatedCallData = { ...data };
      updatedCallData.participantID = callDetail?.participantID;
      store.dispatch(setCallDetail(updatedCallData));
    }
  });
};

export const _addCallParticipants = (payload) => {
  socket.emit("call-att-add", { doc: payload });
};

export const _getAddedCallParticipants = () => {
  socket.on("call-att-add", (data) => {
    const state = store.getState();
    const { callDetail } = state.call;

    if (callDetail?.id === data.id) {
      const { participantID } = callDetail;
      let participant = Array.from(participantID);
      let updatedCallDetails = { ...callDetail };
      data?.participantID?.forEach((element) => {
        const isUserAlreadyExist = participant.findIndex(
          (filterItem) => filterItem.id === element?.id,
        );

        if (isUserAlreadyExist === -1) {
          participant.push(element);
        } else {
          participant[isUserAlreadyExist] = element;
        }
      });
      updatedCallDetails["participantID"] = participant;
      store.dispatch(setCallDetail(updatedCallDetails));
    }
  });
};

export const _callActiveGroup = (payload) => {
  return new Promise((resolve) => {
    socket.emit("call-active-group", { doc: payload }, (res) => {
      return resolve(res?.data ?? {});
    });
  });
};

export const _sendRoom = (roomDetail) => {
  return new Promise((resolve) => {
    socket.emit("call-doc", { doc: roomDetail }, (res) => {
      return resolve(res?.data ?? {});
    });
  });
};
